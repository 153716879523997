export enum TokenSymbol {
  lpXFI = 'lpXFI',
  esXFI = 'esXFI',
  xfi = 'XFI',
  eth = 'eth',
  weth = 'weth',
  usdt = 'usdt',
  xUsd = 'xusd',
  wxfi = 'wxfi',
  usdc = 'usdc',
  wbtc = 'wbtc',
  fomo = 'FOMO',
  bnb = 'bnb',
  sol = 'sol',
}
