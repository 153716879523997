import { NativeCurrency, Token } from '@uniswap/sdk-core'

import { WXFI_TESTNET, WXFI_MAINNET } from './app-contracts'
import { SupportedChainId } from './chainsinfo'

export const WXFI_EXTENDED: { [chainId: number]: Token } = {
  [SupportedChainId.XFI_TESTNET]: new Token(SupportedChainId.XFI_TESTNET, WXFI_TESTNET, 18, 'WXFI', 'Wrapped XFI'),
  [SupportedChainId.XFI_MAINNET]: new Token(
    SupportedChainId.XFI_MAINNET,
    WXFI_MAINNET,
    18,
    'WXFI',
    'Wrapped XFI'
  ),
}

export class NativeXFI extends NativeCurrency {
  protected constructor(chainId: number) {
    super(chainId, 18, 'XFI', 'XFI')
  }

  public get wrapped(): Token {
    if (this.chainId in WXFI_EXTENDED) return WXFI_EXTENDED[this.chainId]
    throw new Error('Unsupported chain ID')
  }

  private static _cache: { [chainId: number]: NativeXFI } = {}

  public static onChain(chainId: number): NativeXFI {
    return this._cache[chainId] ?? (this._cache[chainId] = new NativeXFI(chainId))
  }

  public equals(other: NativeCurrency | Token): boolean {
    return other.isNative && other.chainId === this.chainId
  }
}
