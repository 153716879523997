import { useCallback, useEffect, useState } from 'react'

import { useLocalStorage } from './useLocalStorage'

const urlParams = new URLSearchParams(window.location.search)

export const useSavedRefCode = () => {
  const [refCode, setRefCode] = useLocalStorage<string>('refCode_v2', '')
  const [loading, setLoading] = useState(false)
  const [isCodeAvailable, setIsCodeAvailable] = useState(false)
  const [lastCheckedCode, setLastCheckedCode] = useState<string>()

  const checkCode = useCallback(
    async (refCodeText: string): Promise<boolean> => {
      if (!refCodeText) {
        return false
      }

      if (lastCheckedCode === refCodeText) {
        return isCodeAvailable
      }

      setLoading(true)
      try {
        // TODO: Implement referral code check logic for Ethereum
        const result = false
        setIsCodeAvailable(result)
        setLoading(false)
        setLastCheckedCode(refCodeText)
        return result
      } catch (e) {
        console.error('Failed to check referral code', e)
        return false
      }
    },
    [isCodeAvailable, lastCheckedCode]
  )

  return {
    refCode,
    setRefCode,
    loading,
    isCodeAvailable,
    checkCode,
  }
}

export const useReferralCodeFromParams = () => {
  const { setRefCode, checkCode } = useSavedRefCode()

  useEffect(() => {
    const code = urlParams.get('ref')
    if (code) {
      setRefCode(code)
      checkCode(code)
    }
  }, [setRefCode, checkCode])
}
