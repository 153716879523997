import pairedTokensIcon from 'assets/icons/paired-tokens.svg'
import { ScientificNumber } from 'components/blocks/ScientificNumber'
import Loader from 'components/Loader'
import { formatUnits } from 'ethers/lib/utils'
import { useTokenBalance } from 'hooks/base/token'
import { useTokenAsset } from 'hooks/useTokenAsset'
import { useMemo } from 'react'
import styled from 'styled-components'

import { BaseTokenListItem } from '../base/BaseTokenListItem'
import { TokenIcon, TokenSymbol } from '../styles'
import { ILPToken } from './types'

const PairedTokens = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 2rem;
    height: 2rem;
  }

  img:nth-child(2) {
    margin-left: -0.25rem;
  }
`

const EarnedIncentives = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;

  color: var(--TEXT-60, rgba(67, 70, 71, 0.6));
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 16px;
  line-height: 120%; /* 19.2px */
`

const BalanceIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`

interface LPTokenListItemProps {
  token: ILPToken
  onClick: () => void
  selected?: boolean
}

export const LPTokenListItem = ({ token, onClick, selected }: LPTokenListItemProps) => {
  const token0Data = useTokenAsset(token.token0)
  const token1Data = useTokenAsset(token.token1)

  const balance = useTokenBalance(token.address)
  const parsedBalance = useMemo(() => formatUnits(balance, token.decimals), [balance, token.decimals])

  return (
    <BaseTokenListItem
      token={token}
      onClick={onClick}
      selected={selected}
      renderTokenContent={(token) => (
        <>
          <PairedTokens>
            <TokenIcon
              src={token0Data?.icon}
              alt={token.symbol}
              onError={(e) => {
                e.currentTarget.src = '/images/tokens/unknown.png'
              }}
            />
            <TokenIcon
              src={token1Data?.icon}
              alt={token.symbol}
              onError={(e) => {
                e.currentTarget.src = '/images/tokens/unknown.png'
              }}
            />
          </PairedTokens>
          <div>
            <TokenSymbol style={{ fontSize: '1.375rem' }}>{token.symbol}</TokenSymbol>
          </div>
        </>
      )}
      renderRightContent={(token, selected) => {
        if (selected) return null
        if (!parsedBalance) return <Loader size="small" />

        return (
          <EarnedIncentives>
            <ScientificNumber value={parsedBalance} />
            <BalanceIcon src={pairedTokensIcon} alt="Paired Tokens" />
          </EarnedIncentives>
        )
      }}
    />
  )
}
