import { Button, CardCentered, FlexContainer } from 'components'
import { ScientificNumber } from 'components/blocks/ScientificNumber'
import Loading from 'components/Loading'
import { formatUnits } from 'ethers/lib/utils'
import { useTokenAsset } from 'hooks/useTokenAsset'
import { t } from 'i18next'
import { useClaimRewards } from 'lib/hooks/farm/useClaimRewards'
import { useClaimState } from 'lib/hooks/farm/useClaimState'
import { useState } from 'react'
import styled from 'styled-components'
import { Address } from 'viem'

import { PendingClaimView } from './PendingClaimView'

const Container = styled(CardCentered)`
  margin: 0;
  padding: 2rem 1.5rem;
  flex-direction: column;
  max-width: 29.875rem;
  max-height: 29.875rem;
  overflow: hidden;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.text100};
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.01875rem;
  margin: 0;
  margin-bottom: 0.75rem;

  ${({ theme }) =>
    theme.mediaWidth.upToTablet`
    font-size: 1.5rem;
    letter-spacing: -0.015rem;
  `}
`

const RewardBox = styled.div`
  display: flex;
  padding: 1.5rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
  border-radius: 0.75rem;
  border: 1px solid rgba(67, 70, 71, 0.15);
`

const RewardTitle = styled.h4`
  color: var(--TEXT-40, rgba(67, 70, 71, 0.4));
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
`

const TokenIcon = styled.img`
  width: 2rem;
  height: 2rem;
  position: relative;
  background: black;
  border-radius: 62.5rem;
  overflow: hidden;

  &::after {
    content: '';
    width: 1.1875rem;
    height: 1.1875rem;
    position: absolute;
    left: 0.4125rem;
    top: 0.4125rem;
    background: white;
  }
`

const Text = styled.div<{ $isUsd?: boolean }>`
  color: var(--TEXT-50, rgba(67, 70, 71, 0.5));
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
`

const StyledTokenIcon = styled(TokenIcon)`
  width: 1.25rem;
  height: 1.25rem;
`

const StyledButton = styled(Button)`
  width: 100%;
`

const TokenInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 1rem;

  b {
    color: var(--TEXT-100, #434647);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 550;
    line-height: 120%;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 0.75rem;

  ${({ theme }) =>
    theme.mediaWidth.upToTablet`
    font-size: 1.125rem;
  `}

  ${TokenIcon} {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const PairedToken = styled.div`
  display: flex;
  align-items: center;

  ${TokenIcon} {
    width: 1.5rem;
    height: 1.5rem;

    &:last-child {
      margin-left: -0.25rem;
    }
  }
`

const RewardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;
  padding-right: 0.5625rem;
`

const NoRewards = styled.div`
  color: var(--TEXT-40, rgba(67, 70, 71, 0.4));

  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 26.4px */
  margin-top: 1rem;
`

interface RewardBlockProps {
  incentiveIcon?: string
  amount?: string
  pool: {
    token0: string
    token1: string
  }
  farmAddress: Address
  onSuccess: (hash: string) => void
}

export const RewardBlock = ({ incentiveIcon, amount = '0.00', pool, farmAddress, onSuccess }: RewardBlockProps) => {
  const token0 = useTokenAsset(pool?.token0)
  const token1 = useTokenAsset(pool?.token1)
  const { claim, isLoading } = useClaimRewards(farmAddress)

  const handleClaim = async () => {
    try {
      const result = await claim()
      if (result?.hash) {
        onSuccess(result.hash)
      }
    } catch (error) {
      console.error('Failed to claim:', error)
    }
  }

  return (
    <RewardBox>
      <FlexContainer
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ flex: 1 }}>
          <RewardTitle>{t('Farm.rewardTitle')}</RewardTitle>
          <Row>
            <TokenIcon src={incentiveIcon} />
            <ScientificNumber value={amount} />
          </Row>
        </div>
        <div style={{ flex: 1, minWidth: 'max-content' }}>
          <RewardTitle>{t('Farm.pool')}</RewardTitle>
          <Row>
            <PairedToken>
              <TokenIcon src={token0?.icon} />
              <TokenIcon src={token1?.icon} />
            </PairedToken>
            <span>
              {token0?.symbol?.toUpperCase()} / {token1?.symbol?.toUpperCase()}
            </span>
          </Row>
        </div>
      </FlexContainer>
      <StyledButton variant="outlined" onClick={handleClaim} disabled={isLoading}>
        {isLoading ? t('Farm.pendingClaimButton') : t('Farm.claimButton')}
      </StyledButton>
    </RewardBox>
  )
}

export const Claim = () => {
  const token = useTokenAsset('xfi')
  const { isLoading, hasRewards, rewards, totalReward, refetch } = useClaimState()
  const [pendingHash, setPendingHash] = useState<string>()
  const [pendingFarmAddress, setPendingFarmAddress] = useState<Address>()

  function getPendingClaimReward(farmAddress: Address) {
    const reward = rewards.find((reward) => {
      return reward.farmAddress === farmAddress
    })
    return reward?.reward ? formatUnits(reward.reward, 18) : '0.00'
  }

  const handleSuccess = (hash: string, farmAddress: Address) => {
    setPendingHash(hash)
    setPendingFarmAddress(farmAddress)
  }

  if (isLoading) {
    return (
      <Container>
        <Title>{t('Farm.claim')}</Title>
        <Loading loadingLabel={t('Farm.loading')} loading={true}>
          <div />
        </Loading>
      </Container>
    )
  }

  if (pendingHash && pendingFarmAddress) {
    return (
      <Container>
        <Title>{t('Farm.claim')}</Title>
        <PendingClaimView
          hash={pendingHash}
          amountIn={getPendingClaimReward(pendingFarmAddress)}
          onBack={() => {
            setPendingHash(undefined)
            setPendingFarmAddress(undefined)
            refetch()
          }}
        />
      </Container>
    )
  }

  if (!hasRewards) {
    return (
      <Container>
        <Title>{t('Farm.claim')}</Title>
        <NoRewards>{t('Farm.noRewards')}</NoRewards>
      </Container>
    )
  }

  return (
    <Container>
      <Title>{t('Farm.claim')}</Title>
      <TokenInfo>
        <Text>{t('Farm.totalReward')}</Text>
        <StyledTokenIcon src={token?.icon} />
        <Text>{token?.symbol}</Text>
        <b>
          <ScientificNumber value={totalReward} />
        </b>
      </TokenInfo>

      <RewardList>
        {rewards.map((reward) => (
          <RewardBlock
            key={reward.farmAddress}
            incentiveIcon={token?.icon}
            amount={formatUnits(reward.reward, 18)}
            farmAddress={reward.farmAddress}
            pool={{
              token0: reward.token0,
              token1: reward.token1,
            }}
            onSuccess={(hash) => handleSuccess(hash, reward.farmAddress)}
          />
        ))}
      </RewardList>
    </Container>
  )
}
