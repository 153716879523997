import { MaxUint256 } from '@ethersproject/constants'
import erc20Abi from 'abis/erc20.json'
import { useSwapRouter } from 'constants/app-contracts'
import { BigNumber } from 'ethers'
import { useContract } from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import { StepStatus } from 'pages/Pools/AddLiquidity/AddLIqudityProgress'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface ApprovalStep {
  name: string
  status: StepStatus
}

export interface ApprovalConfig {
  tokenInAddress: string
  tokenInSymbol: string
  tokenInAmount?: BigNumber
  tokenOutAddress: string
  tokenOutSymbol: string
  tokenOutAmount?: BigNumber
  isXfiIn?: boolean
  isXfiOut?: boolean
}

interface ApprovalState {
  isApproved: boolean
  isPending: boolean
  isLoading: boolean
}

export function useApprovalSteps(config: ApprovalConfig) {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const router = useSwapRouter()

  const [approvalStates, setApprovalStates] = useState<Record<string, ApprovalState>>({})
  const [pendingTxs, setPendingTxs] = useState<Record<string, string>>({})

  // Use refs to store previous values for comparison
  const prevAllowancesRef = useRef<{
    tokenIn: string
    tokenOut: string
  }>({ tokenIn: '0', tokenOut: '0' })

  // Create contracts for tokens
  const tokenInContract = useContract(config.tokenInAddress, erc20Abi)
  const tokenOutContract = useContract(config.tokenOutAddress, erc20Abi)

  // Check allowances function
  const checkAllowances = useCallback(async () => {
    if (!account || !router?.address) return

    try {
      // For native token (when contract is null), we consider it always approved
      const allowanceIn = tokenInContract ? await tokenInContract.allowance(account, router.address) : MaxUint256
      const allowanceOut = tokenOutContract ? await tokenOutContract.allowance(account, router.address) : MaxUint256

      // Update previous values
      prevAllowancesRef.current = {
        tokenIn: allowanceIn.toString(),
        tokenOut: allowanceOut.toString(),
      }

      setApprovalStates((prev) => ({
        ...prev,
        [config.tokenInAddress]: {
          isApproved: !tokenInContract || (config.tokenInAmount ? allowanceIn.gte(config.tokenInAmount) : false),
          isPending: !!pendingTxs[config.tokenInAddress],
          isLoading: false,
        },
        [config.tokenOutAddress]: {
          isApproved: !tokenOutContract || (config.tokenOutAmount ? allowanceOut.gte(config.tokenOutAmount) : false),
          isPending: !!pendingTxs[config.tokenOutAddress],
          isLoading: false,
        },
      }))
    } catch (error) {
      console.error('Failed to check allowances:', error)
    }
  }, [
    account,
    router?.address,
    config.tokenInAddress,
    config.tokenOutAddress,
    config.tokenInAmount,
    config.tokenOutAmount,
    tokenInContract,
    tokenOutContract,
    pendingTxs,
  ])

  // Subscribe to Approval events only for non-native tokens
  useEffect(() => {
    if (!account || !router?.address) return

    const handleApproval = (owner: string, spender: string) => {
      if (owner.toLowerCase() === account.toLowerCase() && spender.toLowerCase() === router.address.toLowerCase()) {
        checkAllowances()
      }
    }

    if (tokenInContract) {
      tokenInContract.on('Approval', handleApproval)
    }
    if (tokenOutContract) {
      tokenOutContract.on('Approval', handleApproval)
    }

    return () => {
      if (tokenInContract) {
        tokenInContract.off('Approval', handleApproval)
      }
      if (tokenOutContract) {
        tokenOutContract.off('Approval', handleApproval)
      }
    }
  }, [tokenInContract, tokenOutContract, account, router?.address, checkAllowances])

  // Check allowances only when relevant dependencies change
  useEffect(() => {
    checkAllowances()
  }, [
    account,
    config.tokenInAddress,
    config.tokenOutAddress,
    config.tokenInAmount?.toString(),
    config.tokenOutAmount?.toString(),
    router?.address,
    checkAllowances,
  ])

  // Generate steps
  const steps = useMemo(() => {
    const stateIn = approvalStates[config.tokenInAddress]
    const stateOut = approvalStates[config.tokenOutAddress]

    const getTokenStatus = (state?: ApprovalState, amount?: BigNumber) => {
      if (!amount || amount.isZero()) return StepStatus.PENDING
      if (!state) return StepStatus.PENDING
      if (state.isApproved) return StepStatus.COMPLETE
      if (state.isPending) return StepStatus.PENDING
      return StepStatus.CURRENT
    }

    const statusFirst = getTokenStatus(stateIn, config.tokenInAmount)
    const statusSecond = getTokenStatus(stateOut, config.tokenOutAmount)

    if (config.isXfiIn) {
      return [
        {
          name: t('pools.approve') + ' ' + config.tokenOutSymbol,
          status: statusSecond,
        },
        {
          name: t('pools.provideLiquidity'),
          status: statusSecond === StepStatus.COMPLETE ? StepStatus.CURRENT : StepStatus.PENDING,
        },
      ]
    }

    if (config.isXfiOut) {
      return [
        {
          name: t('pools.approve') + ' ' + config.tokenInSymbol,
          status: statusFirst,
        },
        {
          name: t('pools.provideLiquidity'),
          status: statusFirst === StepStatus.COMPLETE ? StepStatus.CURRENT : StepStatus.PENDING,
        },
      ]
    }

    return [
      {
        name: t('pools.approve') + ' ' + config.tokenInSymbol,
        status: statusFirst,
      },
      {
        name: t('pools.approve') + ' ' + config.tokenOutSymbol,
        status: statusSecond,
      },
      {
        name: t('pools.provideLiquidity'),
        status:
          statusFirst === StepStatus.COMPLETE && statusSecond === StepStatus.COMPLETE
            ? StepStatus.CURRENT
            : StepStatus.PENDING,
      },
    ]
  }, [approvalStates, config, t])

  // Function to update state after approve
  const refreshApproval = useCallback(
    async (tokenAddress: string) => {
      await checkAllowances()
    },
    [checkAllowances]
  )

  return {
    steps,
    approvalStates,
    refreshApproval,
  }
}
