import { BigNumber } from 'ethers'
import { ZERO } from 'utils/isZero'
import { formatDecimal, getPrecision } from 'utils/numberWithCommas'

export const useExchangeRate = (amountIn: BigNumber, amountOut: BigNumber, decimalsIn: number, decimalsOut: number) => {
  if (amountIn.isZero() || amountOut.isZero()) return ZERO

  const precision = getPrecision(decimalsOut)
  const rate = amountOut.mul(BigNumber.from(10).pow(decimalsIn)).div(amountIn)

  return formatDecimal(rate, precision, decimalsOut)
}
