import ArrowDownIcon from 'assets/icons/arrow-down.svg'
import WalletIcon from 'assets/svg/wallet.svg'
import { StatusIcon } from 'components/Identicon/StatusIcon'
import { getConnection } from 'connection'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import { useActiveWeb3React } from 'hooks/web3'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { animated, useSpring } from 'react-spring'
import { ApplicationModal } from 'state/application/actions'
import { TransactionDetails } from 'state/transactions/types'
import styled from 'styled-components'

import { useModalOpen, useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { shortenAddress } from '../../utils'
import Loader from '../Loader'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModal/WalletModal'

const Web3StatusConnect = styled.button`
  color: ${({ theme }) => theme.text100};
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.0125rem;
  text-transform: uppercase;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  ${({ theme }) =>
    theme.mediaWidth.upToTablet`
    display: flex;
    align-items: center;
    justify-content: center;
  `};

  &:hover {
    text-decoration: underline;
  }
`

const ArrowDown = styled(animated.img)`
  width: 1rem;
  height: 1rem;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 0.75rem;
    height: 0.75rem;
  `};
`

const Web3StatusConnected = styled.div<{ pending?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const Text = styled.div`
  font-size: 1.125rem;
  line-height: 120%; /* 1.35rem */
  letter-spacing: -0.01125rem;
  text-transform: uppercase;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 0.875rem;
  `};
`

interface IWebStatus {
  text?: string
  className?: string
}

function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

export default function Web3Status({ text, className }: IWebStatus) {
  const { account, connector } = useActiveWeb3React()
  const { t } = useTranslation()
  const open = useModalOpen(ApplicationModal.WALLET)

  const isMobile = useIsMobileDevice()

  const toggleWalletModal = useWalletModalToggle()

  const rotateAnimation = useSpring({
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
    config: {
      duration: 200,
    },
  })

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length

  if (account) {
    return (
      <>
        <Web3StatusConnected
          id="web3-status-connected"
          onClick={toggleWalletModal}
          pending={hasPendingTransactions}
          className={className}
        >
          {connector && <StatusIcon account={account} connection={getConnection(connector)} />}
          {hasPendingTransactions ? (
            <RowBetween>
              <Text>{pending?.length} Pending</Text> <Loader stroke="white" />
            </RowBetween>
          ) : (
            <>
              <Text>{shortenAddress(account, isMobile ? 2 : 3)}</Text>
            </>
          )}
          <ArrowDown style={rotateAnimation} src={ArrowDownIcon} />
        </Web3StatusConnected>
        <WalletModal />
      </>
    )
  } else {
    return (
      <>
        <Web3StatusConnect id="connect-wallet" onClick={toggleWalletModal} className={className}>
          {isMobile ? <img src={WalletIcon} alt="wallet" /> : text || t('Header.connect')}
        </Web3StatusConnect>
        <WalletModal />
      </>
    )
  }
}
