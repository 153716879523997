import { useQuery } from '@tanstack/react-query'
import { API_URL } from 'api/api'
import { useMemo } from 'react'

import { PairData, PairsState } from './types'

const DEBUG_TAG = 'USE_AVAILABLE_PAIRS'

type PairsResponse = PairData[]

const fetchPairs = async (url: string): Promise<PairsResponse> => {
  const response = await fetch(url)
  const data = await response.json()
  return data
}

/**
 * Hook for fetching available pairs
 */
export const useAvailablePairs = (): PairsState => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['availablePairs'],
    queryFn: () => fetchPairs(`${API_URL}overview/pairs`),
    staleTime: 30000, // Consider data stale after 30 seconds
    gcTime: 60000, // Keep data in cache for 1 minute
  })

  return useMemo(() => {
    try {
      const defaultState: PairsState = {
        pairs: [],
        loading: isLoading,
        error: undefined,
      }

      if (!data || !Array.isArray(data)) {
        return defaultState
      }

      const pairs = data as PairData[]
      const availablePairs = pairs.filter((pair) => !pair.is_blacklisted)

      return {
        pairs: availablePairs,
        loading: isLoading,
        error: undefined,
      }
    } catch (err) {
      console.error(`[${DEBUG_TAG}] Error processing pairs:`, err)
      return {
        pairs: [],
        loading: false,
        error: err instanceof Error ? err.message : 'Unknown error',
      }
    }
  }, [data, isLoading, error])
}
