import { SupportedChainId } from 'constants/chainsinfo'
import { RPC_PROVIDERS } from 'constants/providers'
import { BigNumber } from 'ethers'
import { useActiveWeb3React } from 'hooks/web3'
import { useEffect, useState } from 'react'
import { ZERO } from 'utils/isZero'

/**
 * Returns native currency balance using direct RPC provider
 * This is more reliable than using web3-react provider
 */
export function useNativeCurrencyBalanceV2(): BigNumber {
  const [balance, setBalance] = useState<BigNumber>(ZERO)
  const { account, chainId } = useActiveWeb3React()

  useEffect(() => {
    if (!account || !chainId) {
      console.log('[NativeBalanceV2] No account or chainId:', { account, chainId })
      return
    }

    const provider = RPC_PROVIDERS[chainId as SupportedChainId]
    if (!provider) {
      console.log('[NativeBalanceV2] No provider for chainId:', chainId)
      return
    }

    const fetchBalance = async () => {
      try {
        const balance = await provider.getBalance(account)

        setBalance(balance)
      } catch (error) {
        console.error('[NativeBalanceV2] Failed to fetch balance:', error)
      }
    }

    fetchBalance()
    // retrieve balance every 10 seconds
    const interval = setInterval(fetchBalance, 10000)

    return () => clearInterval(interval)
  }, [account, chainId])

  return balance
}
