import styled from 'styled-components'

import { formatNumber } from '../../../utils/numberWithCommas'

const Container = styled.div`
  display: inline-flex;
  align-items: baseline;
`

const ZerosPart = styled.sub`
  font-size: 0.75em;
  margin: 0 2px;
  color: ${({ theme }) => theme.text2};
  position: relative;
  top: 5px;
`

interface ScientificNumberProps {
  value: string | number
  decimals?: number
}

export const ScientificNumber = ({ value }: ScientificNumberProps) => {
  const formatScientificNotation = (value: string | number) => {
    // Handle invalid or undefined values
    if (value === undefined || value === null || value === '') {
      return { prefix: '0', zeros: 0, significant: '0' }
    }

    const num = value.toString()

    // Handle values with suffixes (k, m, b, etc.)
    const suffixMatch = num.match(/([0-9.]+)([kmbt])/i)
    if (suffixMatch) {
      const [, numberPart, suffix] = suffixMatch
      const formattedNumber = Number(numberPart).toFixed(2)
      return { prefix: `${formattedNumber}${suffix}`, zeros: 0, significant: '' }
    }

    const numericValue = Number(num)

    // Handle NaN or invalid numbers
    if (isNaN(numericValue)) {
      return { prefix: '0', zeros: 0, significant: '0' }
    }

    // Handle large numbers using formatNumber utility with 2 decimal places
    if (Math.abs(numericValue) >= 1000) {
      return { prefix: formatNumber(numericValue, 2), zeros: 0, significant: '' }
    }

    // If the number is in scientific notation or very small, convert it to decimal
    const normalizedNum = numericValue.toFixed(20)

    if (numericValue === 0) return { prefix: '0', zeros: 0, significant: '0' }

    const [integerPart, decimalPart = ''] = normalizedNum.split('.')

    // If number is >= 1 or has no decimal part, return it formatted with 2 decimals
    if (!decimalPart || Math.abs(Number(integerPart)) >= 1) {
      return { prefix: Number(normalizedNum).toFixed(2), zeros: 0, significant: '' }
    }

    const leadingZeros = decimalPart.match(/^0*/)?.[0]?.length || 0
    const significantPart = decimalPart.slice(leadingZeros).slice(0, 2)

    // If there is only one leading zero or no leading zeros, return as regular decimal
    if (leadingZeros <= 1) return { prefix: Number(normalizedNum).toFixed(2), zeros: 0, significant: '' }

    return {
      prefix: '0.0',
      zeros: leadingZeros - 1,
      significant: significantPart,
    }
  }

  const { prefix, zeros, significant } = formatScientificNotation(value)

  if (zeros === 0) return <span>{prefix}</span>

  return (
    <Container>
      {prefix}
      {zeros > 0 && <ZerosPart>{zeros}</ZerosPart>}
      {significant}
    </Container>
  )
}
