import { ZERO_ADDRESS } from 'constants/misc'
import { BigNumber, Contract } from 'ethers'
import { useActiveWeb3React } from 'hooks/web3'
import { useCallback, useEffect, useState } from 'react'
import { Address, isAddress } from 'viem'

import { ERC20_ABI } from '../../constants/abis'
import { ZERO } from '../../utils/zero'

export type ApprovalState = 'PENDING' | 'SUCCESS' | 'ERROR' | 'LOADING' | 'NOT_APPROVED'

export const useTokenApprove = (
  tokenAddress: Address | string | undefined,
  spenderAddress: Address | undefined,
  amount: BigNumber | undefined
) => {
  const { provider, account } = useActiveWeb3React()
  const [approvalState, setApprovalState] = useState<ApprovalState>('NOT_APPROVED')

  // Check if token is native
  const isNativeToken = tokenAddress === ZERO_ADDRESS

  // Get allowance
  const getAllowance = useCallback(async () => {
    if (!tokenAddress || !spenderAddress || !account || !provider || !amount) {
      return
    }

    if (isNativeToken) {
      setApprovalState('SUCCESS')
      return
    }

    if (!isAddress(tokenAddress)) {
      setApprovalState('ERROR')
      return
    }

    try {
      setApprovalState('LOADING')
      const contract = new Contract(tokenAddress as Address, ERC20_ABI, provider)
      const allowance = await contract.allowance(account, spenderAddress)

      if (allowance.gte(amount)) {
        setApprovalState('SUCCESS')
      } else {
        setApprovalState('NOT_APPROVED')
      }
    } catch (error) {
      console.error('Error checking allowance:', error)
      setApprovalState('ERROR')
    }
  }, [tokenAddress, spenderAddress, account, provider, amount, isNativeToken])

  // Approve token
  const approve = useCallback(async () => {
    if (!tokenAddress || !spenderAddress || !account || !provider || !amount) {
      return
    }

    if (isNativeToken) {
      return
    }

    if (!isAddress(tokenAddress)) {
      setApprovalState('ERROR')
      return
    }

    try {
      setApprovalState('PENDING')
      const signer = provider.getSigner()
      const contract = new Contract(tokenAddress as Address, ERC20_ABI, signer)

      const tx = await contract.approve(spenderAddress, amount)
      await tx.wait()

      setApprovalState('SUCCESS')
    } catch (error) {
      console.error('Error approving token:', error)
      setApprovalState('ERROR')
    }
  }, [tokenAddress, spenderAddress, account, provider, amount, isNativeToken])

  // Check initial allowance
  useEffect(() => {
    if (!amount || amount.eq(ZERO)) {
      setApprovalState('SUCCESS')
      return
    }

    getAllowance()
  }, [amount, getAllowance])

  return {
    approvalState,
    approve,
  }
}
