import { TransactionResponse } from '@ethersproject/providers'
import { BigNumber, PopulatedTransaction } from 'ethers'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useAddPopup } from 'state/application/hooks'
import { ITxData, useHasPendingNftAction, useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { ZERO } from 'utils/isZero'

import { useActiveWeb3React } from '../web3'

type AsyncFunc = (data?: any) => Promise<PopulatedTransaction | undefined>

export interface ITxInfo {
  usedGasLimit?: BigNumber
  estimatedGasLimitFunc: (showError?: boolean) => Promise<BigNumber>
}

export interface ITxTemplate {
  pending: boolean
  action: (data?: any) => Promise<void>
  disabled: boolean
  calledWallet: boolean
  isError: boolean
  txInfo: ITxInfo
}

export const useTxTemplate = (
  type: string,
  description: string,
  successMsg: string,
  funcTxData: AsyncFunc,
  txCallback?: (tx: TransactionResponse) => void,
  failMsg?: any,
  txSavingParams?: {
    bridge: {
      fromChainId: number
      toChainId: number
    }
  }
): ITxTemplate => {
  const { account, chainId, provider: library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const [disabled, setDisabled] = useState(false)
  const [usedGasLimit, setUsedGasLimit] = useState<BigNumber | undefined>(ZERO)
  const [isError, setIsError] = useState(false)

  const pending = useHasPendingNftAction(description)
  const addPopup = useAddPopup()
  const [calledWallet, setCalledWallet] = useState(false)

  // Add ref to track the last error
  const lastErrorRef = useRef<string>('')

  const estimatedGasLimit = useCallback(
    async (showError?: boolean) => {
      if (!chainId || !library || !account) return ZERO

      if (account) {
        const txData = await funcTxData()

        const txn = {
          ...txData,
          value: txData?.value || '0x0',
        }

        try {
          const estimatedCost = await library.getSigner().estimateGas(txn)
          // Clear error state on successful estimation
          setIsError(false)
          lastErrorRef.current = ''
          return calculateGasMargin(chainId, estimatedCost)
        } catch (error: any) {
          console.error('Failed to estimate transaction', error)

          // Only update error state if it's a new error
          const errorMessage = error?.message || 'Unknown error'
          if (lastErrorRef.current !== errorMessage) {
            lastErrorRef.current = errorMessage
            setIsError(true)

            if (showError) {
              addPopup({
                msg: {
                  success: false,
                  title: <>Transaction Error</>,
                  description: error?.message || 'Can not estimate gas usage for transaction',
                },
              })
            }
          }
        }
      }

      return ZERO
    },
    [funcTxData, account, chainId, library, addPopup]
  )

  const action = useCallback(
    async (data?: any) => {
      setIsError(false)
      if (!chainId || !library || !account) return

      if (account) {
        const txData = await funcTxData(data)
        const txn = {
          ...txData,
          value: txData?.value || '0x0',
        }

        const gazLimit = await estimatedGasLimit(true)

        try {
          const newTxn: ITxData = {
            ...txn,
            gasLimit: gazLimit,
          }

          setCalledWallet(true)

          return await library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              setUsedGasLimit(gazLimit)

              txCallback && txCallback(response)

              addTransaction(response, {
                summary: successMsg,
                nftAction: {
                  nftAddress: '',
                  tokenId: '',
                  type: description,
                },
                type,
                txData: newTxn,
                ...txSavingParams,
              })
            })
        } catch (error) {
          console.error('Failed to send transaction', error)

          // Check if error is user rejection
          const isUserRejection =
            error?.code === 4001 ||
            (error?.message && error.message.toLowerCase().includes('user rejected transaction'))

          if (isUserRejection) {
            console.warn('🚫 [TxTemplate] User rejected transaction')
          } else {
            failMsg &&
              addPopup({
                msg: {
                  success: false,
                  title: <>Transaction Denied</>,
                  description: failMsg,
                },
              })

            setIsError(true)
            setDisabled(true)
            console.error(error)
          }
        } finally {
          setCalledWallet(false)
        }
      } else {
        return
      }
    },
    [
      successMsg,
      description,
      type,
      funcTxData,
      account,
      addTransaction,
      chainId,
      library,
      addPopup,
      failMsg,
      estimatedGasLimit,
      txCallback,
      txSavingParams,
    ]
  )

  return useMemo(
    (): ITxTemplate => ({
      pending,
      action,
      disabled,
      calledWallet,
      isError,
      txInfo: { usedGasLimit, estimatedGasLimitFunc: estimatedGasLimit },
    }),
    [pending, action, disabled, estimatedGasLimit, usedGasLimit, isError, calledWallet]
  )
}
