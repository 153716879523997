import { useTokenAsset } from 'hooks/useTokenAsset'

import { BaseTokenListItem } from '../base/BaseTokenListItem'
import { TokenIcon, TokenName, TokenRelativeBlock, TokenSymbol } from '../styles'
import { ISwapToken } from './types'

interface SwapTokenListItemProps {
  token: ISwapToken
  onClick: () => void
  selected?: boolean
  showChainIcon?: boolean
}

export const SwapTokenListItem = ({ token, onClick, selected, showChainIcon }: SwapTokenListItemProps) => {
  const tokenData = useTokenAsset(token.address ? token.address : token.symbol)

  return (
    <BaseTokenListItem
      token={token}
      onClick={onClick}
      selected={selected}
      renderTokenContent={(token) => (
        <>
          <TokenRelativeBlock>
            <TokenIcon
              src={tokenData?.icon}
              alt={token.symbol}
              onError={(e) => {
                e.currentTarget.src = '/images/tokens/unknown.png'
              }}
            />
            {tokenData?.chainIcon && showChainIcon && (
              <TokenIcon
                src={tokenData?.chainIcon}
                alt={token.symbol}
                onError={(e) => {
                  e.currentTarget.src = '/images/tokens/unknown.png'
                }}
                style={{ position: 'absolute', bottom: 0, right: '-4px', width: '16px', height: '16px' }}
              />
            )}
          </TokenRelativeBlock>
          <div>
            <TokenSymbol>{token.symbol}</TokenSymbol>
            <TokenName>{tokenData?.name}</TokenName>
          </div>
        </>
      )}
    />
  )
}
