import { TransactionResponse } from '@ethersproject/providers'
import { API_URL } from 'api/api'
import { TokenSymbol } from 'components/blocks/AmountInput/TokenSymbol'
import { usePoolContract, useSwapRouter, useUSDTAddress, useWXFIAddress } from 'constants/app-contracts'
import { WXFI_TESTNET } from 'constants/app-contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { TxTemplateTypes } from 'constants/transactions'
import { BigNumber } from 'ethers'
import { useTxTemplate } from 'hooks/base/tx-template'
import { useApiCall } from 'hooks/useApiCall'
import { useERC20Symbol } from 'hooks/useContractName'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { useActiveWeb3React } from 'hooks/web3'
import { ContractTxState, useContractTransaction } from 'lib/hooks/useContractTransaction'
import { usePairAddress } from 'lib/hooks/usePairAddress'
import { useAddLiquidityInfo } from 'pages/Pools/AddLiquidity/hooks/api/useAddLiquidityInfo'
import { ILiquidityResults, ILiquidityToken, useLockedInPool } from 'pages/Pools/InfoBox'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSingleCallResult } from 'state/multicall/hooks'
import { ZERO } from 'utils/isZero'
import { Address } from 'viem'

import { ISwapToken } from './types/tokens'

interface IPathApiResponse {
  path: string[]
  amount_out: number
}

interface IPathApiErrorResponse {
  detail: string
}

interface IPathResult {
  Path?: string[]
  Error?: string
  AmountOut?: number
}

const ONE_WEY = '1'

const useGetSwapPath = (
  token0?: string,
  token1?: string,
  amountIn = ONE_WEY
): {
  result?: IPathResult
  pending: boolean
} => {
  const wxfiAddress = useWXFIAddress()

  const url = useMemo(() => {
    const targetIn = token0?.toLowerCase() === TokenSymbol.xfi.toLowerCase() ? wxfiAddress : token0
    const targetOut = token1?.toLowerCase() === TokenSymbol.xfi.toLowerCase() ? wxfiAddress : token1

    if (!targetIn || !targetOut) {
      return ''
    }

    return `${API_URL}swap/route?token0=${targetIn}&token1=${targetOut}&amount_in=${amountIn?.toString()}`
  }, [token0, token1, amountIn, wxfiAddress])

  const { data, isLoading, error } = useApiCall<IPathApiResponse | IPathApiErrorResponse>(url)

  useEffect(() => {
    if (error) {
      console.error('[SwapAPI:Error]', error)
    }
  }, [url, data, error, token0, token1, amountIn, wxfiAddress])

  const result = useMemo(() => {
    if (!data) return undefined

    // If API returned error with detail
    if ('detail' in data) {
      return {
        Path: undefined,
        Error: data.detail,
        AmountOut: undefined,
      }
    }

    // Validate API response
    if (!data.path || !Array.isArray(data.path) || !data.amount_out) {
      console.error('[SwapAPI:ValidationError]', {
        path: data.path,
        amount_out: data.amount_out,
      })
      return {
        Path: undefined,
        Error: 'Invalid API response',
        AmountOut: undefined,
      }
    }

    // If API returned successful response
    return {
      Path: data.path,
      Error: undefined,
      AmountOut: data.amount_out,
    }
  }, [data])

  return {
    result,
    pending: isLoading,
  }
}

const XFI_API_MODEL: ISwapToken = {
  address: ZERO_ADDRESS,
  decimals: 18,
  name: 'XFI',
  symbol: TokenSymbol.xfi,
  is_blacklisted: false,
}

export const useSwapTokensList = (): {
  result?: ISwapToken[]
  pending: boolean
} => {
  const { data, isLoading } = useApiCall<ISwapToken[]>(`${API_URL}overview/tokens`)

  return useMemo(() => {
    // Handle cases when data is not iterable
    const tokens = Array.isArray(data) ? data : []

    return {
      result: tokens.concat(XFI_API_MODEL),
      pending: isLoading,
    }
  }, [data, isLoading])
}

// Convert scientific notation to decimal string
export const fromScientific = (num: number): string => {
  if (typeof num !== 'number' || isNaN(num) || !isFinite(num) || num === 0) {
    return '0'
  }
  // Convert to string in scientific notation
  const str = num.toExponential()

  // Split into coefficient and exponent
  const [coefficient, exponent] = str.split('e')
  const exp = parseInt(exponent)

  // Convert coefficient to decimal
  const [whole, fraction = ''] = coefficient.split('.')
  const wholePart = whole.replace(/^[+-]/, '')
  const fractionPart = fraction.replace(/0+$/, '')

  // Calculate decimal places needed
  if (exp >= 0) {
    // Move decimal point right
    const zeros = '0'.repeat(Math.max(0, exp - fractionPart.length))
    return `${wholePart}${fractionPart}${zeros}`
  } else {
    // Move decimal point left
    const zeros = '0'.repeat(Math.abs(exp) - 1)
    return `0.${zeros}${wholePart}${fractionPart}`
  }
}

const getDeadline = () => Math.floor(Date.now() / 1000) + 60 * 20 // 20 minutes from the current Unix time

export const useAddLiquiditySwap = (
  token0?: string,
  token1?: string,
  amount0: BigNumber = ZERO,
  amount1: BigNumber = ZERO,
  setPendingTx?: (txHash: string) => void
) => {
  const contract = useSwapRouter()
  const { account = '' } = useActiveWeb3React()

  const { result: addLiquidityInfo, pending: loadingAddLiquidityInfo } = useAddLiquidityInfo({
    token0: token0 || '',
    token1: token1 || '',
    user: account || '',
    amount0: amount0?.toString() || '0',
    amount1: amount1?.toString() || '0',
    slippage: 0.01,
  })

  const { result: path, pending: loadingPath } = useGetSwapPath(token0, token1)

  const isXfiIn = token0 === ZERO_ADDRESS
  const isXfiOut = token1 === ZERO_ADDRESS

  const { execute: executeTx, state: txState, error: txError, hash } = useContractTransaction()

  useEffect(() => {
    if (hash) {
      setPendingTx && setPendingTx(hash)
    }
  }, [hash, setPendingTx])

  const handleAddLiquidity = useCallback(async () => {
    if (!addLiquidityInfo?.data || !contract?.address) {
      console.error('[AddLiquiditySwap] Missing liquidity info data or contract address')
      return
    }

    const callData = addLiquidityInfo.data as `0x${string}`

    try {
      await executeTx({
        callData,
        transferValue: isXfiIn ? amount0.toString() : isXfiOut ? amount1.toString() : '0',
        contractAddress: contract.address as Address,
        description: `Add liquidity ${token0}/${token1}`,
      })
    } catch (error) {
      console.error('[AddLiquiditySwap] Failed to execute transaction:', error)
    }
  }, [addLiquidityInfo, executeTx, contract?.address, token0, token1, isXfiIn, isXfiOut, amount0, amount1])

  const isPending = loadingAddLiquidityInfo || txState === ContractTxState.LOADING
  const isError = txState === ContractTxState.ERROR || !!txError
  const calledWallet = txState !== ContractTxState.UNKNOWN

  return {
    handleAddLiquidity,
    action: handleAddLiquidity,
    pending: isPending,
    isError,
    calledWallet,
    txInfo: addLiquidityInfo,
    txState,
    txError,
    hash,
    path,
    loadingPath,
    isXfiIn,
    isXfiOut,
  }
}

export interface IRemoveLiquiditySnapshot {
  token0: ILiquidityToken
  token1: ILiquidityToken
  data: ILiquidityResults
}

export const useRemoveLiquidity = (
  pair: string,
  tokenIn: ILiquidityToken,
  tokenOut: ILiquidityToken,
  amount: BigNumber = ZERO,
  setPendingTx?: (txHash: string) => void
) => {
  const contract = useSwapRouter()

  const data = useLockedInPool(pair, tokenIn.address, tokenOut.address, amount)

  const [txSnapshot, setSnapshotTx] = useState<IRemoveLiquiditySnapshot>({
    token0: tokenIn,
    token1: tokenOut,
    data,
  })

  const { account = '' } = useActiveWeb3React()

  const { result: path, pending } = useGetSwapPath(tokenIn?.address, tokenOut?.address)

  const dataFunc = useCallback(async () => {
    const deadline = getDeadline() // 20 minutes from the current Unix time

    if (!tokenIn || !tokenOut) return

    const isXfiIn = tokenIn.address?.toLowerCase() === TokenSymbol.xfi.toLowerCase()
    const isXfiOut = tokenOut.address?.toLowerCase() === TokenSymbol.xfi.toLowerCase()

    if (isXfiIn || isXfiOut) {
      return await contract?.populateTransaction.removeLiquidityETH(
        isXfiIn ? tokenOut.address : tokenIn.address,
        amount,
        0,
        0,
        account,
        deadline
      )
    }

    return await contract?.populateTransaction.removeLiquidity(
      tokenIn.address,
      tokenOut.address,
      amount,
      0,
      0,
      account,
      deadline
    )
  }, [contract, amount, account, tokenIn, tokenOut])

  const setTx = useCallback(
    (tx: TransactionResponse) => {
      setPendingTx && setPendingTx(tx.hash)

      setSnapshotTx({
        token0: tokenIn,
        token1: tokenOut,
        data,
      })
    },
    [setPendingTx, data, setSnapshotTx, tokenIn, tokenOut]
  )

  return {
    ...useTxTemplate(
      TxTemplateTypes.Swapped,
      `$remove_liquidity_${tokenIn}_${tokenOut}`,
      `Removed liquidity`,
      dataFunc,
      setTx
    ),
    path,
    loadingPath: pending,
    txSnapshot,
    data,
  }
}

export const XFI_MODEL = {
  address: ZERO_ADDRESS,
  name: 'XFI',
  symbol: TokenSymbol.xfi,
  decimals: 18,
}

export const useSwapTokens = () => {
  const parsed = useParsedQueryString()

  const usdtAddress = useUSDTAddress()

  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const tmpIn = (parsed?.in as string) || WXFI_TESTNET
  const tmpOut = tmpIn === usdtAddress ? WXFI_TESTNET : (parsed?.out as string) || usdtAddress
  const [tokenIn, setTokenIn] = useState<string>(tmpIn)
  const [tokenOut, setTokenOut] = useState<string>(tmpOut)

  const [amountOut, setAmountOut] = useState<BigNumber>()
  const [amountIn, setAmountIn] = useState<BigNumber>()

  const noValue = !amountIn || amountIn.isZero()
  const { result, pending: loadingAssets } = useSwapTokensList()

  const allowedAssets = useMemo(() => {
    return result || []
  }, [result])

  const tokensInList = useMemo(() => {
    const targetAddress = tokenOut?.toLowerCase()
    return (
      allowedAssets
        ?.filter((item) => item.address.toLowerCase() !== targetAddress)
        .map((token) => ({
          ...token,
          label: token.name,
          address: token.address,
        })) || []
    )
  }, [allowedAssets, tokenOut])

  const tokensOutList = useMemo(() => {
    const targetAddress = tokenIn?.toLowerCase()
    return (
      allowedAssets
        ?.filter((item) => item.address.toLowerCase() !== targetAddress)
        .map((token) => ({
          ...token,
          label: token.name,
          address: token.address,
        })) || []
    )
  }, [allowedAssets, tokenIn])

  const tokenOutModel = useMemo(
    () => tokensOutList.find((item) => item.address === tokenOut) /* || XFI_MODEL */,
    [tokenOut, tokensOutList]
  )

  const tokenInModel = useMemo(
    () => tokensInList.find((item) => item.address === tokenIn) /* || XFI_MODEL */,
    [tokenIn, tokensInList]
  )

  return {
    pendingTx,
    setPendingTx,
    tokenIn,
    setTokenIn,
    tokenOut,
    setTokenOut,
    amountOut,
    setAmountOut,
    amountIn,
    setAmountIn,
    noValue,
    loadingAssets,
    tokensInList,
    tokensOutList,
    tokenOutModel,
    tokenInModel,
  }
}

const useFormTokens = (tokenIn?: string, tokenOut?: string) => {
  const { result, pending: loadingAssets } = useSwapTokensList()

  const allowedAssets = useMemo(() => {
    return result || []
  }, [result])

  const tokensInList = useMemo(() => {
    const targetAddress = tokenOut?.toLowerCase()
    return (
      allowedAssets
        ?.filter((item) => item.address.toLowerCase() !== targetAddress)
        .map((token) => ({
          ...token,
          address: token.address,
        })) || []
    )
  }, [allowedAssets, tokenOut])

  const tokensOutList = useMemo(() => {
    const targetAddress = tokenIn?.toLowerCase()
    return (
      allowedAssets
        ?.filter((item) => item.address.toLowerCase() !== targetAddress)
        .map((token) => ({
          ...token,
          address: token.address,
        })) || []
    )
  }, [allowedAssets, tokenIn])

  const tokenOutModel = useMemo(
    () => tokensOutList.find((item) => item.address.toLowerCase() === tokenOut?.toLowerCase()) || XFI_MODEL,
    [tokenOut, tokensOutList]
  )

  const tokenInModel = useMemo(
    () => tokensInList.find((item) => item.address.toLowerCase() === tokenIn?.toLowerCase()) || XFI_MODEL,
    [tokenIn, tokensInList]
  )

  return {
    loadingAssets,
    tokensInList,
    tokensOutList,
    tokenOutModel,
    tokenInModel,
  }
}

export const useLiquidityTokens = (inputToken0?: string, inputToken1?: string) => {
  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const [tokenIn, setTokenIn] = useState<string>(inputToken0 || '')
  const [tokenOut, setTokenOut] = useState<string>(inputToken1 || '')

  useEffect(() => {
    inputToken0 && setTokenIn(inputToken0)
    inputToken1 && setTokenOut(inputToken1)
  }, [inputToken0, inputToken1])

  const [amountOut, setAmountOut] = useState<BigNumber>()
  const [amountIn, setAmountIn] = useState<BigNumber>()

  const pair = usePair(tokenIn, tokenOut)

  const { loading: loadingReserves, mapped } = useReserves(pair.pair)

  const noValue = !amountIn || amountIn.isZero()

  const { loadingAssets, tokensInList, tokensOutList, tokenOutModel, tokenInModel } = useFormTokens(tokenIn, tokenOut)

  return {
    pendingTx,
    setPendingTx,
    tokenIn,
    setTokenIn,
    tokenOut,
    setTokenOut,
    amountOut,
    setAmountOut,
    amountIn,
    setAmountIn,
    noValue,
    loadingAssets,
    tokensInList,
    tokensOutList,
    tokenOutModel,
    tokenInModel,
    reserves: mapped,
    loadingReserves,
    pair: pair.pair,
  }
}

export const useRemoveLiquidityTokens = (
  inputToken0: string | undefined,
  inputToken1: string | undefined,
  lp: string
) => {
  const [pendingTx, setPendingTx] = useState<string | undefined>('')

  const tokenIn = inputToken0
  const tokenOut = inputToken1

  const [amountIn, setAmountIn] = useState<BigNumber>()

  const { symbol: symbolFirst } = useERC20Symbol(tokenIn)
  const { symbol: symbolSecond } = useERC20Symbol(tokenOut)

  const { loadingAssets, tokensInList, tokensOutList, tokenOutModel, tokenInModel } = useFormTokens(tokenIn, tokenOut)

  const pair = usePair(tokenIn, tokenOut)

  const noValue = !amountIn || amountIn.isZero()

  const pairModel = useMemo(
    () => ({
      symbol: symbolFirst && symbolSecond ? `${symbolFirst}/${symbolSecond}` : 'LP',
      address: pair.pair,
    }),
    [pair.pair, symbolFirst, symbolSecond]
  )

  return {
    tokenIn,
    tokenOut,
    amountIn,
    setPendingTx,
    noValue,
    pendingTx,
    pairModel,
    setAmountIn,
    loadingAssets,
    tokensInList,
    tokensOutList,
    tokenOutModel,
    tokenInModel,
    pair: pair.pair,
  }
}

export const usePair = (
  tokenIn?: string,
  tokenOut?: string
): {
  pair: string
  loading: boolean
} => {
  const { pair, loading } = usePairAddress(tokenIn, tokenOut)

  return {
    pair,
    loading,
  }
}

interface PairInfo {
  token0R: { result?: string; loading: boolean; error: boolean }
  token1R: { result?: string; loading: boolean; error: boolean }
  reservesR: { result?: { _reserve0: any; _reserve1: any }[]; loading: boolean; error: boolean }
}

export const usePairInfo = (pair: string): PairInfo => {
  const contract = usePoolContract(pair)

  const reservesR = useSingleCallResult(contract, 'getReserves')
  const token0R = useSingleCallResult(contract, 'token0')
  const token1R = useSingleCallResult(contract, 'token1')

  return {
    token0R,
    token1R,
    reservesR,
  }
}

export const useReserves = (pair: string) => {
  const { reservesR, token0R, token1R } = usePairInfo(pair)

  return useMemo(() => {
    const token0 = token0R.result?.toString().toLowerCase()
    const token1 = token1R.result?.toString().toLowerCase()

    if (reservesR.loading || token0R.loading || token1R.loading) return { loading: true }

    const mapped: {
      [key: string]: BigNumber | undefined
    } =
      token0 && token1
        ? {
            [token0]: reservesR.result?.[0]?._reserve0,
            [token1]: reservesR.result?.[0]?._reserve1,
          }
        : {}

    return {
      loading: false,
      mapped,
    }
  }, [reservesR, token0R, token1R])
}
