import { Contract } from '@ethersproject/contracts'
import farmABI from 'abis/farm/farm.json'
import { useContract } from 'hooks/useContract'
import { useCallback, useState } from 'react'
import { Address } from 'viem'

interface FarmContract extends Contract {
  getReward: () => Promise<{ hash: string; wait: () => Promise<any> }>
}

export const useClaimRewards = (farmAddress: Address) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [hash, setHash] = useState<string>()

  const farmContract = useContract<FarmContract>(farmAddress as string, farmABI, true)

  const claim = useCallback(async () => {
    if (!farmContract) return

    try {
      setIsLoading(true)
      setError(null)

      const tx = await farmContract.getReward()
      setHash(tx.hash)
      return { hash: tx.hash }
    } catch (err) {
      console.error('Failed to claim rewards:', err)
      setError(err instanceof Error ? err : new Error('Failed to claim rewards'))
      return null
    } finally {
      setIsLoading(false)
    }
  }, [farmContract])

  return {
    claim,
    isLoading,
    hash,
    error,
  }
}
