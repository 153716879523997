import { useEffect, useMemo, useState } from 'react'

export const useIgnoreUserRejection = (error: string | undefined): string | undefined => {
  const [isUserRejection, setIsUserRejection] = useState(false)

  useEffect(() => {
    if (isUserRejection) {
      console.warn('🚫 [useIgnoreUserRejection] User rejected transaction')
    }
  }, [isUserRejection])

  return useMemo(() => {
    if (!error) return undefined

    // Check if error contains user rejection message
    const isUserRejection = error.toLowerCase().includes('user rejected transaction')

    setIsUserRejection(isUserRejection)
    return isUserRejection ? undefined : error
  }, [error])
}
