import farmABI from 'abis/farm/farm.json'
import { BigNumber } from 'ethers'
import { useContract } from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import { useCallback } from 'react'
import { Address } from 'viem'

export const useStakeLPTokens = (farmAddress?: Address) => {
  const { account } = useActiveWeb3React()
  const farmContract = useContract(farmAddress, farmABI)

  const stake = useCallback(
    async (amount: BigNumber) => {
      if (!farmContract || !account) return

      try {
        const tx = await farmContract.stake(amount)
        return tx
      } catch (error) {
        console.error('Error staking LP tokens:', error)
        throw error
      }
    },
    [farmContract, account]
  )

  const withdraw = useCallback(
    async (amount: BigNumber) => {
      if (!farmContract || !account) return

      try {
        const tx = await farmContract.withdraw(amount)
        return tx
      } catch (error) {
        console.error('Error withdrawing LP tokens:', error)
        throw error
      }
    },
    [farmContract, account]
  )

  const getStaked = useCallback(
    async (address: string) => {
      if (!farmContract) return BigNumber.from(0)

      try {
        return await farmContract.balanceOf(address)
      } catch (error) {
        console.error('Error getting staked balance:', error)
        return BigNumber.from(0)
      }
    },
    [farmContract]
  )

  return {
    stake,
    withdraw,
    getStaked,
  }
}
