import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useWalletModalToggle } from '../../state/application/hooks'

const ConnectButton = styled.button`
  color: ${({ theme }) => theme.text100};
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.0125rem;
  text-transform: uppercase;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

interface Web3ConnectBtnProps {
  className?: string
  as?: any
  theme?: 'light' | 'dark'
  text?: string
}

export const Web3ConnectBtn = ({ className, as: El = ConnectButton, text }: Web3ConnectBtnProps) => {
  const toggleWalletModal = useWalletModalToggle()
  const { t } = useTranslation()

  return (
    <El className={className} onClick={toggleWalletModal}>
      {text ?? t('Header.connect')}
    </El>
  )
}

export default Web3ConnectBtn
