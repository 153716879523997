import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import { ZERO } from 'utils/isZero'

import { useSwapStore } from '../../../store/useSwapStore'
import { ISwapToken } from '../../../types'
import { useSwapTokensList } from '../../../utils'
import { SwapAmounts } from '../types'

const DEBUG_TAG = 'USE_SWAP_AMOUNTS'

/**
 * Convert scientific notation to decimal string
 */
const fromScientific = (num: number | string): string => {
  const str = num.toString()
  if (!/\d+\.?\d*e[+-]*\d+/i.test(str)) {
    return str
  }

  const [base, exponent] = str.toLowerCase().split('e')
  const exp = parseInt(exponent)

  // Get number of decimal places in base
  const decimalPlaces = base.includes('.') ? base.split('.')[1].length : 0

  // Remove decimal point
  const baseWithoutDecimal = base.replace('.', '')

  if (exp < 0) {
    return '0.' + '0'.repeat(Math.abs(exp) - 1) + baseWithoutDecimal
  }

  // Add zeros based on exponent minus decimal places already in base
  return baseWithoutDecimal + '0'.repeat(exp - decimalPlaces)
}

/**
 * Calculate swap output amount based on input amount
 */
export const useSwapAmountOut = (amountIn: BigNumber = ZERO, tokenIn?: string, tokenOut?: string): SwapAmounts => {
  const route = useSwapStore((state) => state.route)
  const { result: tokens, pending: tokensPending } = useSwapTokensList()

  const outputToken = useMemo(() => {
    if (!tokenOut || !tokens) return undefined
    return tokens.find((t: ISwapToken) => t?.address?.toLowerCase() === tokenOut.toLowerCase())
  }, [tokenOut, tokens])

  const amountOut = useMemo(() => {
    if (!route.amount_out || !outputToken || !amountIn || amountIn?.isZero()) return ZERO

    try {
      // Convert BigInt to string before creating BigNumber

      const amountOut = BigNumber.from(route.amount_out.toString().replace('n', ''))
      return amountOut
    } catch (error) {
      console.error(`[${DEBUG_TAG}:Error]`, error)
      return ZERO
    }
  }, [route.amount_out, outputToken, amountIn])

  return {
    amountOut,
    loading: tokensPending,
    error: undefined,
  }
}
