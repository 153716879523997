import { useXUSDAddress } from 'constants/app-contracts'
import { useMemo } from 'react'
import { ZERO } from 'utils/isZero'

import { PointsAmount, PointsCalculationParams, PointsConfig } from '../../types/points'
import { useSwapAmountOut } from '../prices'
import { calculateBonusPoints, hasXUsdInPath } from './utils'

const DEBUG_TAG = 'USE_POINTS_AMOUNT'

const DEFAULT_CONFIG: PointsConfig = {
  basePercentage: 10,
  xUsdMultiplier: 2,
}

/**
 * Hook for calculating bonus points for swap
 */
export const usePointsAmount = (
  params: PointsCalculationParams,
  config: PointsConfig = DEFAULT_CONFIG
): PointsAmount => {
  const { tokenIn, tokenOut, amountIn, amountOut, path } = params
  const xUsdAddress = useXUSDAddress()

  // Calculate amount out for xUSD path
  const { amountOut: contractAmountOut, loading: loadingAmountOut } = useSwapAmountOut(amountIn, tokenIn, xUsdAddress)

  return useMemo(() => {
    try {
      // Direct xUSD swap cases
      if (tokenIn === xUsdAddress) {
        return {
          points: calculateBonusPoints(amountIn, config.basePercentage),
          loading: false,
        }
      }

      if (tokenOut === xUsdAddress) {
        return {
          points: calculateBonusPoints(amountOut, config.basePercentage),
          loading: false,
        }
      }

      // Loading state
      if (loadingAmountOut) {
        return {
          points: ZERO,
          loading: true,
        }
      }

      // xUSD route case - double points
      if (hasXUsdInPath(path, xUsdAddress)) {
        return {
          points: calculateBonusPoints(contractAmountOut, config.basePercentage)?.mul(config.xUsdMultiplier),
          loading: false,
        }
      }

      // Default case - no points
      return {
        points: ZERO,
        loading: false,
      }
    } catch (error) {
      console.error(`[${DEBUG_TAG}] Error calculating points:`, {
        error,
        params,
        config,
      })
      return {
        points: ZERO,
        loading: false,
      }
    }
  }, [tokenIn, tokenOut, amountIn, amountOut, path, xUsdAddress, contractAmountOut, loadingAmountOut, config])
}
