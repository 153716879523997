import { useQuery } from '@tanstack/react-query'
import { INDEXER_URL } from 'constants/api'
import request from 'graphql-request'
import { Address } from 'viem'

interface IncentivesApyResponse {
  incentivesApies: {
    apy: string
    id: string
    timestamp: string
    staking: string
  }[]
}

const APY_QUERY = `
  query GetStakingApy($staking: String!) {
    incentivesApies(
      where: { staking_eq: $staking }
      orderBy: timestamp_DESC
      limit: 1
    ) {
      apy
      id
      timestamp
      staking
    }
  }
`

/**
 * Hook for fetching staking APY from indexer
 * @param stakingAddress - The address of the staking contract
 */
export const useStakingApy = (stakingAddress: Address) => {
  return useQuery({
    queryKey: ['stakingApy', stakingAddress],
    queryFn: async () => {
      const data = await request<IncentivesApyResponse>(INDEXER_URL, APY_QUERY, {
        staking: stakingAddress.toLowerCase(),
      })
      return data.incentivesApies[0]?.apy || '0'
    },
    staleTime: 30000, // Consider data stale after 30 seconds
    gcTime: 60000, // Keep data in cache for 1 minute
    enabled: Boolean(stakingAddress),
  })
}
