import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import { useTokenAsset } from 'hooks/useTokenAsset'

import { BaseTokenBadge } from '../base/TokenBadge'
import { TokenIcon, TokenRelativeBlock, TokenSymbol } from '../styles'
import { ILPToken } from './types'

interface LPTokenBadgeProps {
  token: ILPToken
  onClick: () => void
  withoutOptions?: boolean
}

export const LPTokenBadge = ({ token, onClick, withoutOptions }: LPTokenBadgeProps) => {
  const token0Data = useTokenAsset(token.token0)
  const token1Data = useTokenAsset(token.token1)

  const isMobile = useIsMobileDevice()

  return (
    <BaseTokenBadge
      token={token}
      onClick={onClick}
      withoutOptions={withoutOptions}
      renderTokenIcon={(token) => (
        <TokenRelativeBlock>
          <TokenIcon
            src={token0Data?.icon}
            alt={token.symbol}
            onError={(e) => {
              e.currentTarget.src = '/images/tokens/unknown.png'
            }}
            style={{ marginRight: '-0.25rem' }}
          />
          <TokenIcon
            src={token1Data?.icon}
            alt={token.symbol}
            onError={(e) => {
              e.currentTarget.src = '/images/tokens/unknown.png'
            }}
          />
        </TokenRelativeBlock>
      )}
      renderSymbol={(token) => (!isMobile ? <TokenSymbol>{token.symbol}</TokenSymbol> : null)}
    />
  )
}
