import { useTokenAsset } from 'hooks/useTokenAsset'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BaseAmountInput } from '../base/BaseAmountInput'
import { TokenListModal } from '../base/TokenListModal'
import { LPTokenBadge } from './LPTokenBadge'
import { LPTokenListItem } from './LPTokenListItem'
import { ILPToken, LPAmountInputProps } from './types'

const PairedTokens = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.25rem;

  img {
    width: 2rem;
    aspect-ratio: 1;
  }

  img:nth-child(2) {
    margin-left: -0.25rem;
  }
`

const TokenListItem = ({ token, onClick, selected }: { token: ILPToken; onClick: () => void; selected?: boolean }) => {
  const token0Data = useTokenAsset(token.token0)
  const token1Data = useTokenAsset(token.token1)
  const { t } = useTranslation()

  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '1rem',
        cursor: 'pointer',
        background: selected ? 'rgba(216, 236, 255, 0.3)' : 'transparent',
      }}
    >
      <PairedTokens>
        <img
          src={token0Data?.icon}
          alt={token.symbol}
          onError={(e) => {
            e.currentTarget.src = '/images/tokens/unknown.png'
          }}
        />
        <img
          src={token1Data?.icon}
          alt={token.symbol}
          onError={(e) => {
            e.currentTarget.src = '/images/tokens/unknown.png'
          }}
        />
      </PairedTokens>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
        <div>{token.symbol}</div>
        {token.earnedIncentives && (
          <div style={{ fontSize: '0.875rem', color: '#7A8B9D' }}>
            {t('earned')}: {token.earnedIncentives}
          </div>
        )}
      </div>
    </div>
  )
}

export const LPAmountInput = (props: LPAmountInputProps) => {
  const { t } = useTranslation()

  const filterLPTokens = useCallback((tokens: ILPToken[], search: string) => {
    const searchLower = search.toLowerCase()
    return tokens.filter(
      (token) =>
        token.symbol.toLowerCase().includes(searchLower) ||
        token.address.toLowerCase().includes(searchLower) ||
        token.earnedIncentives?.toLowerCase().includes(searchLower)
    )
  }, [])

  return (
    <BaseAmountInput<ILPToken>
      {...props}
      customComponents={{
        tokenBadge: ({ token, onClick }) => (
          <LPTokenBadge token={token} onClick={onClick} withoutOptions={!props.rightTokenOptions?.length} />
        ),
        tokenList: ({ isOpen, onDismiss, tokens, selectedToken, onSelect }) => (
          <TokenListModal
            isOpen={isOpen}
            onDismiss={onDismiss}
            tokens={tokens}
            selectedToken={selectedToken}
            onSelect={onSelect}
            title={t('tokenSelector.title')}
            searchPlaceholder={t('tokenSelector.search')}
            showSearch={props.showSearch}
            filterTokens={filterLPTokens}
            renderToken={({ token, onClick, selected }) => (
              <LPTokenListItem token={token} onClick={onClick} selected={selected} />
            )}
          />
        ),
        ...props.customComponents,
      }}
    />
  )
}

// Export with balance for backward compatibility
export const LPAmountInputWithBalance = LPAmountInput
