import { BigNumber } from '@ethersproject/bignumber'
import { BtnApprovTx } from 'components/Button'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import { useSwapRouter } from 'constants/app-contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { useCurrency } from 'hooks/Tokens'
import { ApprovalState, useSimpleApproveCallback } from 'hooks/useApproveCallback'
import { type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { ConfirmInWalletBlock } from '../../../../components/Approval/ApproveTx'

interface IApproveLiquidityProps {
  children: ReactNode
  tokenIn?: string
  tokenOut?: string
  amountIn?: BigNumber
  amountOut?: BigNumber
  approvalStates: Record<string, { isApproved: boolean; isPending: boolean; isLoading: boolean }>
}

export const ApproveLiquidity = ({
  children,
  tokenIn,
  tokenOut,
  amountIn,
  amountOut,
  approvalStates,
}: IApproveLiquidityProps) => {
  const { t } = useTranslation()
  const currencyIn = useCurrency(tokenIn)
  const currencyOut = useCurrency(tokenOut)
  const router = useSwapRouter()

  const {
    approve: approveIn,
    calledWallet: calledWalletIn,
    approvalState: approvalStateIn,
  } = useSimpleApproveCallback(currencyIn, amountIn || BigNumber.from(0), router?.address)

  const {
    approve: approveOut,
    calledWallet: calledWalletOut,
    approvalState: approvalStateOut,
  } = useSimpleApproveCallback(currencyOut, amountOut || BigNumber.from(0), router?.address)

  // Check if we need to approve any tokens
  const stateIn = tokenIn ? approvalStates[tokenIn] : undefined
  const stateOut = tokenOut ? approvalStates[tokenOut] : undefined

  const needApproveIn =
    tokenIn &&
    !stateIn?.isApproved &&
    amountIn &&
    !amountIn.isZero() &&
    tokenIn !== ZERO_ADDRESS &&
    !currencyIn?.isNative

  const needApproveOut =
    tokenOut &&
    !stateOut?.isApproved &&
    amountOut &&
    !amountOut.isZero() &&
    tokenOut !== ZERO_ADDRESS &&
    !currencyOut?.isNative

  // If either token is in a pending state
  const isPendingIn = stateIn?.isPending || approvalStateIn === ApprovalState.PENDING
  const isPendingOut = stateOut?.isPending || approvalStateOut === ApprovalState.PENDING

  // If we're waiting for wallet confirmation
  const isWaitingForWallet = calledWalletIn || calledWalletOut

  if (isWaitingForWallet) {
    return <ConfirmInWalletBlock calledWallet={true}>{children}</ConfirmInWalletBlock>
  }

  // First token needs approval
  if (needApproveIn) {
    return (
      <BtnApprovTx onClick={approveIn} disabled={isPendingIn}>
        <FormActionBtn
          pending={isPendingIn}
          labelActive={t('Swap.approve') + ' ' + currencyIn?.symbol}
          labelInProgress={t('Swap.approving')}
        />
      </BtnApprovTx>
    )
  }

  // Second token needs approval
  if (needApproveOut) {
    return (
      <BtnApprovTx onClick={approveOut} disabled={isPendingOut}>
        <FormActionBtn
          pending={isPendingOut}
          labelActive={t('Swap.approve') + ' ' + currencyOut?.symbol}
          labelInProgress={t('Swap.approving')}
        />
      </BtnApprovTx>
    )
  }

  // Both tokens are approved
  return <>{children}</>
}
