import { IAppToken } from 'components/blocks/AmountInput/useAppCoins'
import { ITxTemplateInfo } from 'components/TransactionInfo/TransactionInfo'
import { TxStatusView } from 'components/TxStatusView/TxStatusView'
import { BigNumber } from 'ethers'
import { useTokenAsset } from 'hooks/useTokenAsset'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ZERO } from 'utils/isZero'

import { TotalInfo } from './AdditionalInfo'

const Title = styled.h2`
  color: var(--TEXT-100, #434647);
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 36px */
  letter-spacing: -0.3px;
  margin: 0 0 0.5rem;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 1.5rem;
    letter-spacing: -0.24px;
  `}
`

export const PendingSwapView = ({
  onBack,
  hash,
  txInfo,
  assetIn,
  assetOut,
  pointsAmount,
  priceChange,
  slippage,
  path,
  ammountIn = ZERO,
  ammountOut = ZERO,
}: {
  onBack: () => void
  hash: string
  txInfo?: ITxTemplateInfo
  assetIn?: IAppToken
  assetOut?: IAppToken
  pointsAmount?: BigNumber
  priceChange: number
  slippage?: number | string
  ammountIn?: BigNumber
  ammountOut?: BigNumber
  path?: string[]
}) => {
  const [status, setStatus] = useState<'pending' | 'done'>('pending')
  const { t } = useTranslation()

  const tokenOut = useTokenAsset(assetOut?.address)

  return (
    <div>
      <Title
        style={{
          margin: status === 'pending' ? '0 0 1rem' : '0 0 0.5rem',
        }}
      >
        {status === 'pending' ? t('Swap.pending') : t('Swap.done')}
      </Title>
      <TxStatusView
        hash={hash}
        onBack={onBack}
        onStatusChange={setStatus}
        tokenAddress={assetOut?.address ?? ''}
        tokenSymbol={assetOut?.symbol ?? ''}
        tokenDecimals={assetOut?.decimals ?? 18}
        tokenImageUrl={tokenOut?.icon ?? ''}
        showAddToken={!!tokenOut}
      >
        <TotalInfo
          assetIn={assetIn}
          assetOut={assetOut}
          ammountIn={ammountIn}
          ammountOut={ammountOut}
          pointsAmount={pointsAmount}
          priceChange={priceChange}
          slippage={slippage}
          txInfo={txInfo}
          path={path}
        />
      </TxStatusView>
    </div>
  )
}
