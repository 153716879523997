import styled, { css } from 'styled-components'

const Container = styled.div<{ $style?: string }>`
  width: 100%;
  gap: 1.5rem;
  border-radius: 0.75rem;
  padding: 1.25rem 1rem;
  border: 1px solid ${({ theme }) => theme.divStroke};
  cursor: pointer;
  ${({ $style }) =>
    $style &&
    css`
      ${$style}
    `};

  ${({ theme }) =>
    theme.mediaWidth.upToTablet`
    font-size: 0.875rem;
    line-height: 110%;
  `}
`

export const InfoBox = ({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) => {
  return <Container $style={style as unknown as string}>{children}</Container>
}
