import { ZERO_ADDRESS } from 'constants/misc'
import { useMemo } from 'react'
import { ZERO } from 'utils/isZero'

import { useNativeCurrencyBalanceV2 } from '../useNativeCurrencyBalanceV2'
import { BalanceToken, TokenBalance } from './types'
import { useBalanceFormatter } from './useBalanceFormatter'
import { useDirectTokenBalance } from './useDirectTokenBalance'

const DEBUG_TAG = 'USE_TOKEN_BALANCE'

/**
 * Hook for managing token balance
 * Handles both native and ERC20 tokens
 */
export const useTokenBalance = (token?: BalanceToken): TokenBalance => {
  const isNative = useMemo(() => token?.address === ZERO_ADDRESS, [token?.address])
  // Get balance based on token type
  const nativeBalance = useNativeCurrencyBalanceV2()
  const erc20Balance = useDirectTokenBalance(isNative ? undefined : token?.address)

  // Get formatter
  const formatter = useBalanceFormatter()

  return useMemo(() => {
    try {
      // Handle missing token case
      if (!token) {
        return {
          raw: ZERO,
          formatted: '0',
          decimals: 18,
          symbol: '',
          loading: false,
          error: 'No token specified',
        }
      }

      // Get appropriate balance
      const balance = isNative ? nativeBalance : erc20Balance

      // Format balance
      const formatted = formatter.formatBalance(balance || ZERO, token.decimals || 18)

      return {
        raw: balance || ZERO,
        formatted,
        decimals: token.decimals || 18,
        symbol: token.symbol,
        loading: false,
      }
    } catch (error) {
      console.error(`[${DEBUG_TAG}] Error:`, {
        error,
        token,
      })

      return {
        raw: ZERO,
        formatted: '0',
        decimals: token?.decimals || 18,
        symbol: token?.symbol || '',
        loading: false,
        error: 'Failed to load balance',
      }
    }
  }, [token, isNative, nativeBalance, erc20Balance, formatter])
}
