import { useCallback, useEffect, useState } from 'react'

export interface CacheData<T> {
  data: T
  timestamp: number
}

export const useLocalStorageCache = <T>(key: string, ttl: number = 5 * 60 * 1000) => {
  const [cachedData, setCachedData] = useState<T | null>(null)

  const getCachedData = useCallback(() => {
    try {
      const item = window.localStorage.getItem(key)
      if (item) {
        const parsed: CacheData<T> = JSON.parse(item)
        const now = Date.now()
        if (now - parsed.timestamp < ttl) {
          return parsed.data
        }
      }
      return null
    } catch {
      return null
    }
  }, [key, ttl])

  const setCacheData = useCallback(
    (data: T) => {
      try {
        const cacheData: CacheData<T> = {
          data,
          timestamp: Date.now(),
        }
        window.localStorage.setItem(key, JSON.stringify(cacheData))
        setCachedData(data)
      } catch (error) {
        console.error('Failed to cache data:', error)
      }
    },
    [key]
  )

  useEffect(() => {
    const data = getCachedData()
    if (data) {
      setCachedData(data)
    }
  }, [getCachedData])

  return {
    cachedData,
    setCacheData,
    getCachedData,
  }
}
