import { create } from 'zustand'

type SwapPath = string[]

interface SwapRoute {
  path: SwapPath
  amount_out?: bigint
}

interface SwapStore {
  amountIn: number
  amountOut: number
  tokenIn: string
  tokenOut: string
  slippage: number
  error: string
  route: SwapRoute
  setAmountIn: (amountIn: number) => void
  setAmountOut: (amountOut: number) => void
  setTokenIn: (tokenIn: string) => void
  setTokenOut: (tokenOut: string) => void
  setSlippage: (slippage: number) => void
  setError: (error: string) => void
  setRoute: (route: SwapRoute) => void
}

export const useSwapStore = create<SwapStore>()((set) => ({
  amountIn: 0,
  amountOut: 0,
  tokenIn: '',
  tokenOut: '',
  slippage: 0.01,
  error: '',
  route: {
    path: ['', ''],
    amount_out: undefined,
  },
  setAmountIn: (amountIn: number) => set({ amountIn }),
  setAmountOut: (amountOut: number) => set({ amountOut }),
  setTokenIn: (tokenIn: string) => set({ tokenIn }),
  setTokenOut: (tokenOut: string) => set({ tokenOut }),
  setSlippage: (slippage: number) => set({ slippage }),
  setError: (error: string) => set({ error }),
  setRoute: (route: SwapRoute) => set({ route }),
}))
