import Loading from 'components/Loading'
import { useSwapRouter } from 'constants/app-contracts'
import { BigNumber } from 'ethers'
import { useActiveWeb3React } from 'hooks/web3'
import { useTokenBalance } from 'lib/hooks/balances'
import { useIgnoreUserRejection } from 'lib/hooks/errors/useIgnoreUserRejection'
import { useCallback, useEffect, useState } from 'react'
import { ZERO } from 'utils/isZero'
import { Address } from 'viem'

import { AdditionalInfo } from './AdditionalInfo'
import { SwapButton } from './components/SwapButton'
import { SwapInputs } from './components/SwapInputs'
import { SwapWarnings } from './components/SwapWarnings'
import { SwapExecuteState, useSwapExecute } from './hooks/contracts/useSwapExecute'
import { useTokenAmountDisplay, useTokenAmountFormatter } from './hooks/formatting'
import { usePointsAmount } from './hooks/points'
import { useSwapAmountOut, useSwapRoute } from './hooks/prices'
import { useSwapInfo } from './hooks/prices/api/useSwapInfo'
import { useTokenSelection } from './hooks/tokens'
import { PendingSwapView } from './PendingView'
import { useSwapStore } from './store/useSwapStore'
import { ISwapToken } from './types'
import { asHexString } from './utils/hex'

const DEBUG_TAG = 'SWAP_BLOCK'

export default function SwapBlock({
  slippage,
  onPendingTxChange,
}: {
  slippage?: number | string
  onPendingTxChange?: (tx: string) => void
}) {
  // State management
  const { account } = useActiveWeb3React()
  const [pendingTx, setPendingTx] = useState<string>('')
  const [inputAmount, setInputAmount] = useState<BigNumber>(ZERO)

  const swapRouter = useSwapRouter()

  // Token selection and management
  const {
    tokenIn: { selectedToken: tokenIn, tokenModel: tokenInModel, availableTokens: tokensInList },
    tokenOut: { selectedToken: tokenOut, tokenModel: tokenOutModel, availableTokens: tokensOutList },
    loading: loadingAssets,
    handleTokenInChange,
    handleTokenOutChange,
    handleSwapTokens,
    symbolFirst,
    symbolSecond,
  } = useTokenSelection()

  const tokenInBalance = useTokenBalance({
    address: tokenIn as Address,
    symbol: tokenInModel?.symbol || '',
    decimals: tokenInModel?.decimals || 18,
  })

  const tokenOutBalance = useTokenBalance({
    address: tokenOut as Address,
    symbol: tokenOutModel?.symbol || '',
    decimals: tokenOutModel?.decimals || 18,
  })

  // Swap calculations and routing
  const { amountOut, loading: isCalculating, error: amountError } = useSwapAmountOut(inputAmount, tokenIn, tokenOut)
  const { result: swapRoute, pending: loadingRoute } = useSwapRoute(tokenIn, tokenOut, inputAmount?.toString() || '0')
  const setRoute = useSwapStore((state) => state.setRoute)

  useEffect(() => {
    if (swapRoute?.path && !loadingRoute && swapRoute.path.length >= 2) {
      setRoute({
        path: swapRoute.path,
        amount_out: swapRoute.amountOut ? BigInt(swapRoute.amountOut) : undefined,
      })
    }
  }, [swapRoute, loadingRoute, setRoute])

  const swapPath = swapRoute?.path
  const pathError = swapRoute?.error

  // Get swap execution info
  const { result: swapInfo, pending: loadingSwapInfo } = useSwapInfo({
    to: account as Address,
    token0: tokenIn as Address,
    token1: tokenOut as Address,
    amountIn: inputAmount?.toString(),
    slippage: Number(slippage) || 0.05,
  })

  // Points calculation
  const { points: pointsAmount, loading: loadingPoints } = usePointsAmount({
    tokenIn,
    tokenOut,
    amountIn: inputAmount,
    amountOut,
    path: swapPath,
  })

  // Amount formatting
  const inputFormatter = useTokenAmountFormatter(tokenInModel?.decimals)
  const outputFormatter = useTokenAmountFormatter(tokenOutModel?.decimals)
  const amountDisplayFirst = useTokenAmountDisplay(inputAmount, tokenInModel?.decimals)
  const amountDisplaySecond = useTokenAmountDisplay(amountOut, tokenOutModel?.decimals)

  // Amount handlers
  const resetAmounts = useCallback(() => {
    setInputAmount(ZERO)
  }, [])

  const handleInputChange = useCallback(
    (value?: BigNumber) => {
      if (!value || value.isZero()) {
        setInputAmount(ZERO)
        // Reset amountOut by triggering a new calculation with zero input
        setRoute({
          path: ['', ''],
          amount_out: undefined,
        })
      } else {
        setInputAmount(value)
      }
    },
    [setRoute]
  )

  const handleInputFirst = useCallback(
    (v?: BigNumber) => {
      handleInputChange(v)
    },
    [handleInputChange]
  )

  const handleSwapTokensWithAmount = useCallback(() => {
    if (amountOut) {
      setInputAmount(amountOut)
    }
    handleSwapTokens()
  }, [amountOut, handleSwapTokens])

  // Token change handlers
  const handleTokenChangeFirst = useCallback(
    (addressOrSymbol: string) => {
      handleTokenInChange(addressOrSymbol)
      resetAmounts()
    },
    [handleTokenInChange, resetAmounts]
  )

  const handleTokenChangeSecond = useCallback(
    (addressOrSymbol: string) => {
      handleTokenOutChange(addressOrSymbol)
    },
    [handleTokenOutChange]
  )

  // Swap execution
  const { execute: executeSwap, state: swapState, error: swapError, hash } = useSwapExecute()

  useEffect(() => {
    if (hash) {
      setPendingTx(hash)
      onPendingTxChange?.(hash)
    }
  }, [hash, onPendingTxChange])

  const handleBack = useCallback(() => {
    setPendingTx('')
    onPendingTxChange?.('')
  }, [onPendingTxChange])

  const rawError = pathError || amountError || swapInfo?.error || (swapError?.message as string | undefined)
  const error = useIgnoreUserRejection(rawError)

  const handleSwap = useCallback(async () => {
    if (!swapInfo?.data || !swapInfo.transferAddress) {
      console.error(`[${DEBUG_TAG}] Missing swap info data`)
      return
    }

    const callData = asHexString(swapInfo.data)
    if (!callData) {
      console.error(`[${DEBUG_TAG}] Invalid calldata format`)
      return
    }

    try {
      await executeSwap({
        callData,
        transferValue: swapInfo.transferValue,
        tokenIn,
        tokenOut,
      })
    } catch (error) {
      console.error(`[${DEBUG_TAG}] Failed to execute swap:`, error)
    }
  }, [swapInfo, executeSwap])

  if (pendingTx) {
    return (
      <PendingSwapView
        onBack={handleBack}
        hash={pendingTx}
        txInfo={undefined}
        assetIn={tokenInModel as ISwapToken}
        assetOut={tokenOutModel as ISwapToken}
        pointsAmount={pointsAmount}
        priceChange={swapRoute?.priceImpact || 0}
        slippage={slippage}
        path={swapPath || []}
        ammountIn={inputAmount}
        ammountOut={amountOut}
      />
    )
  }

  const isLoading = isCalculating || loadingRoute || loadingSwapInfo || swapState === SwapExecuteState.LOADING

  const exceedsBalance = inputAmount && tokenInBalance?.raw && inputAmount.gt(tokenInBalance.raw)

  return (
    <>
      <SwapInputs
        loadingAssets={loadingAssets}
        amountDisplayFirst={amountDisplayFirst}
        amountDisplaySecond={amountDisplaySecond}
        handleInputFirst={handleInputFirst}
        onSwapTokens={handleSwapTokensWithAmount}
        tokensInList={tokensInList as ISwapToken[]}
        tokensOutList={tokensOutList as ISwapToken[]}
        tokenInModel={tokenInModel as ISwapToken}
        tokenOutModel={tokenOutModel as ISwapToken}
        handleTokenChangeFirst={handleTokenChangeFirst}
        handleTokenChangeSecond={handleTokenChangeSecond}
        account={account}
        inputFormatter={inputFormatter}
        outputFormatter={outputFormatter}
        tokenInBalance={tokenInBalance?.raw}
        tokenOutBalance={tokenOutBalance?.raw}
      />

      <SwapButton
        account={account}
        isCalculating={isLoading}
        loadingPath={loadingRoute}
        amountIn={inputAmount}
        pathError={error}
        tokenIn={tokenIn}
        pending={false}
        calledWallet={false}
        action={handleSwap}
        isError={!!error || !!exceedsBalance}
        txInfo={undefined}
        spenderAddress={(swapInfo?.approvalAddress as Address) || (swapRouter?.address as Address)}
      />

      <SwapWarnings pathError={error} amountIn={inputAmount} priceChange={swapRoute?.priceImpact || 0} />

      {/* <SwapPoints pointsAmount={pointsAmount} loadingPoints={loadingPoints} /> */}

      {account && inputAmount.gt(ZERO) && (
        <Loading loading={isLoading}>
          <AdditionalInfo
            amountIn={inputAmount}
            decimalsIn={tokenInModel?.decimals || 0}
            symbolFirst={symbolFirst}
            symbolSecond={symbolSecond}
            decimalsOut={tokenOutModel?.decimals || 0}
            expectedAmountOut={amountOut}
            slippage={slippage}
            priceChange={swapRoute?.priceImpact || 0}
            txInfo={undefined}
            path={swapPath}
            estimateTransferSeconds={swapInfo?.estimateTransferSeconds}
            fee={swapInfo?.fee}
          />
        </Loading>
      )}
    </>
  )
}
