import { useState } from 'react'

export type InputType = 'first' | 'second' | null

export const useLastChangedInput = () => {
  const [lastChangedInput, setLastChangedInput] = useState<InputType>(null)

  return {
    lastChangedInput,
    setLastChangedInput,
  }
}
