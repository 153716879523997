import { Stack } from '@mui/material'
import { Currency, Token } from '@uniswap/sdk-core'
import { Button, FlexContainer, Web3ConnectBtn } from 'components'
import { ApproveCheckerERC20, ConfirmInWalletBlock } from 'components/Approval/ApproveTx'
import { LPAmountInput } from 'components/blocks/AmountInput/lp/LPAmountInput'
import { ScientificNumber } from 'components/blocks/ScientificNumber'
import { Dots } from 'components/Dots'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import Loading from 'components/Loading'
import { Switcher } from 'components/Switcher'
import { SupportedChainId } from 'constants/chainsinfo'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { useActiveWeb3React } from 'hooks/web3'
import { useTokenBalance } from 'lib/hooks/balances'
import { useFarmLPTokens } from 'lib/hooks/farm/useFarmLPTokens'
import { useStakeLPTokens } from 'lib/hooks/farm/useStakeLPTokens'
import { useStakingApy } from 'lib/hooks/farm/useStakingApy'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ZERO } from 'utils/isZero'
import { Address } from 'viem'

import { InfoBox } from './InfoBox'
import { PendingStakeView } from './PendingStakeView'

const ActionButton = ({
  account,
  loading,
  noValue,
  farmAddress,
  currency,
  amountIn,
  ConfirmBlock,
}: {
  account?: string | null
  loading: boolean
  noValue: boolean
  farmAddress: Address
  currency?: Currency
  amountIn?: BigNumber
  ConfirmBlock: JSX.Element
}) => {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()

  if (!account) {
    return <Web3ConnectBtn as={Button} theme="dark" />
  }

  if (loading) {
    return (
      <Button disabled>
        <Dots>{t('Farm.loading')}</Dots>
      </Button>
    )
  }

  if (noValue) {
    return <Button disabled>{t('Farm.enterAmount')}</Button>
  }

  if (chainId !== SupportedChainId.XFI_MAINNET) {
    return <Button disabled>{t('Farm.wrongNetwork')}</Button>
  }

  if (!chainId || chainId !== SupportedChainId.XFI_MAINNET || !currency) {
    return ConfirmBlock
  }

  return (
    <ApproveCheckerERC20 currency={currency} address={farmAddress} border={amountIn || ZERO}>
      {ConfirmBlock}
    </ApproveCheckerERC20>
  )
}

export default function StakeBlock({ onTypeChange }: { onTypeChange: (type: 'stake' | 'unstake') => void }) {
  const { t } = useTranslation()
  const [isStake, setIsStake] = useState(true)
  const { account } = useActiveWeb3React()

  const [amountIn, setAmountIn] = useState<BigNumber>()
  const [pendingTx, setPendingTx] = useState<string>()
  const [loading, setLoading] = useState(false)

  const { lpTokens, selectedToken, selectedLPToken, setSelectedToken } = useFarmLPTokens()

  const { stake, withdraw } = useStakeLPTokens(selectedLPToken?.farmAddress as Address)

  const { data: apy, isLoading: isApyLoading } = useStakingApy(selectedLPToken?.farmAddress as Address)

  // Get balance for selected LP token
  const lpBalance = useTokenBalance(
    selectedLPToken
      ? {
          address: selectedLPToken.address as Address,
          symbol: selectedLPToken.symbol,
          decimals: selectedLPToken.decimals,
        }
      : undefined
  )

  const handleInputFirst = useCallback(
    (v?: BigNumber) => {
      setAmountIn(v)
    },
    [setAmountIn]
  )

  const handleTokenChange = useCallback(
    (addressOrSymbol: string) => {
      setSelectedToken(addressOrSymbol)
      setAmountIn(undefined)
    },
    [setSelectedToken]
  )

  const noValue = !amountIn || amountIn.isZero()

  const handleStakeOrWithdraw = useCallback(async () => {
    if (!amountIn || !selectedLPToken) return
    setLoading(true)
    try {
      const tx = await (isStake ? stake(amountIn) : withdraw(amountIn))
      if (tx?.hash) {
        setPendingTx(tx.hash)
      }
    } catch (error) {
      console.error('Error while staking/withdrawing:', error)
    } finally {
      setLoading(false)
    }
  }, [amountIn, selectedLPToken, isStake, stake, withdraw])

  const lpFarmTokenBalance = useTokenBalance({
    address: selectedLPToken?.farmAddress as Address,
    symbol: 'LP',
    decimals: 18,
  })

  useEffect(() => {
    onTypeChange(isStake ? 'stake' : 'unstake')
  }, [isStake, onTypeChange])

  const ConfirmBlock = useMemo(() => {
    return (
      <ConfirmInWalletBlock calledWallet={false}>
        {noValue ? (
          <Button disabled>{t('Farm.enterAmount')}</Button>
        ) : (
          <Button onClick={handleStakeOrWithdraw} disabled={false}>
            <FormActionBtn
              pending={false}
              labelActive={t(isStake ? 'Farm.stake' : 'Farm.unstake')}
              labelInProgress={t(isStake ? 'Farm.stakeInProgress' : 'Farm.unstakeInProgress')}
            />
          </Button>
        )}
      </ConfirmInWalletBlock>
    )
  }, [noValue, t, isStake, handleStakeOrWithdraw])

  if (pendingTx) {
    return (
      <PendingStakeView
        token0={selectedLPToken?.token0 ?? ''}
        token1={selectedLPToken?.token1 ?? ''}
        symbol={selectedLPToken?.symbol ?? ''}
        decimals={selectedLPToken?.decimals ?? 18}
        amountIn={amountIn ?? ZERO}
        type={isStake ? 'stake' : 'unstake'}
        onBack={() => setPendingTx('')}
        hash={pendingTx}
      />
    )
  }

  return (
    <>
      {(!isStake || (lpFarmTokenBalance && !lpFarmTokenBalance.raw?.isZero())) && (
        <Switcher
          options={[t('Farm.stake'), t('Farm.unstake')]}
          style={{ width: 'auto', alignSelf: 'flex-start' }}
          onSwitch={(option: string) => setIsStake(option === t('Farm.stake'))}
        />
      )}
      <Stack>
        <LPAmountInput
          inputValue={amountIn}
          setInputValue={handleInputFirst}
          rightTokenOptions={lpTokens}
          rightToken={selectedLPToken}
          onChangeRightToken={handleTokenChange}
          showBalanceRow={!!account}
          balance={isStake ? lpBalance?.raw : lpFarmTokenBalance?.raw}
          max={isStake ? lpBalance?.raw : lpFarmTokenBalance?.raw}
        />

        <InfoBox style={{ gap: '.75rem', display: 'flex', flexDirection: 'column', marginTop: '.75rem' }}>
          <FlexContainer>
            <span>{t('Farm.LPTStaked')}</span>
            <ScientificNumber value={formatUnits(lpFarmTokenBalance?.raw || 0n, 18)} />
          </FlexContainer>
          <FlexContainer>
            <span>{t('Farm.APY')}</span>
            <Loading loadingLabel={t('Farm.loading')} loading={isApyLoading}>
              <span>
                <ScientificNumber value={apy || '0'} />
                <span>%</span>
              </span>
            </Loading>
          </FlexContainer>
        </InfoBox>
      </Stack>

      <ActionButton
        account={account}
        loading={loading}
        noValue={noValue}
        farmAddress={selectedLPToken?.farmAddress as Address}
        currency={
          selectedLPToken
            ? new Token(
                SupportedChainId.XFI_MAINNET,
                selectedLPToken.address,
                selectedLPToken.decimals,
                selectedLPToken.symbol
              )
            : undefined
        }
        amountIn={amountIn}
        ConfirmBlock={ConfirmBlock}
      />
    </>
  )
}
