import { Stack } from '@mui/material'
import replaceSvg from 'assets/icons/replace.svg'
import { SwapAmountInput } from 'components/blocks/AmountInput'
import { BigNumber } from 'ethers'
import styled from 'styled-components'

import { TokenAmountDisplay, TokenAmountFormatter } from '../../hooks/formatting'
import { ISwapToken } from '../../types'

const ExchangeBlock = styled.div`
  position: relative;
  margin: calc(0.75rem / 2) 0;
  cursor: pointer;
`

const AbsoluteSwapIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  cursor: pointer;
  padding: 1rem;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.inputGrayStroke};
  background: ${({ theme }) => theme.white};

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 2.625rem;
    height: 2.625rem;
    padding: 0.5rem;
  `}
`

interface SwapInputsProps {
  loadingAssets: boolean
  amountDisplayFirst: TokenAmountDisplay
  amountDisplaySecond: TokenAmountDisplay
  handleInputFirst: (v?: BigNumber) => void
  onSwapTokens: () => void
  tokensInList: ISwapToken[]
  tokensOutList: ISwapToken[]
  tokenInModel: ISwapToken
  tokenOutModel: ISwapToken
  handleTokenChangeFirst: (addressOrSymbol: string) => void
  handleTokenChangeSecond: (addressOrSymbol: string) => void
  account?: string | null
  inputFormatter: TokenAmountFormatter
  outputFormatter: TokenAmountFormatter
  tokenInBalance?: BigNumber
  tokenOutBalance?: BigNumber
}

export const SwapInputs = ({
  loadingAssets,
  amountDisplayFirst,
  amountDisplaySecond,
  handleInputFirst,
  onSwapTokens,
  tokensInList,
  tokensOutList,
  tokenInModel,
  tokenOutModel,
  handleTokenChangeFirst,
  handleTokenChangeSecond,
  account,
  inputFormatter,
  outputFormatter,
  tokenInBalance,
  tokenOutBalance,
}: SwapInputsProps) => {
  return (
    <Stack>
      <SwapAmountInput
        inputValue={amountDisplayFirst.displayValue}
        setInputValue={handleInputFirst}
        disabled={loadingAssets}
        rightTokenOptions={tokensInList}
        decimals={tokenInModel?.decimals}
        rightToken={tokenInModel}
        onChangeRightToken={handleTokenChangeFirst}
        showBalanceRow={!!account}
        formatter={inputFormatter}
        balance={tokenInBalance}
        max={tokenInBalance}
      />

      <ExchangeBlock onClick={onSwapTokens}>
        <AbsoluteSwapIcon src={replaceSvg} />
      </ExchangeBlock>

      <SwapAmountInput
        inputValue={amountDisplaySecond.displayValue}
        disabled={true}
        rightToken={tokenOutModel}
        decimals={tokenOutModel?.decimals}
        rightTokenOptions={tokensOutList}
        onChangeRightToken={handleTokenChangeSecond}
        validateBalanceExceedsZero={false}
        showBalanceRow={!!account}
        formatter={outputFormatter}
        balance={tokenOutBalance}
      />
    </Stack>
  )
}
