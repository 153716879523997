import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useSwapRouter } from 'constants/app-contracts'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'

import { useSingleCallResult } from '../state/multicall/hooks'
import { useTokenContract } from './useContract'

export function useTokenAllowance(token?: Token, owner?: string, spender?: string) {
  const contract = useTokenContract(token?.address, false)

  const inputs = useMemo(() => [owner, spender || ''], [owner, spender])
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result?.[0]

  const currencyAmount = useMemo(
    () => (token && allowance ? CurrencyAmount.fromRawAmount(token, allowance.toString()) : undefined),
    [token, allowance]
  )
  const bnAllowance = useMemo(
    () => (token && allowance ? BigNumber.from(allowance.toString()) : undefined),
    [token, allowance]
  )

  return {
    bnAllowance,
    currencyAmount,
  }
}

// This hook is specifically for checking allowance for the router
export function useTokenAllowanceForRouter(token?: Token, owner?: string) {
  const router = useSwapRouter()
  return useTokenAllowance(token, owner, router?.address)
}
