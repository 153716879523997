import { Stack } from '@mui/material'
import { FlexContainer } from 'components'
import { TokenIcon } from 'components/blocks/AmountInput/styles'
import { TokenRelativeBlock } from 'components/blocks/AmountInput/styles'
import { ScientificNumber } from 'components/blocks/ScientificNumber'
import { TxStatusView } from 'components/TxStatusView/TxStatusView'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { useTokenAsset } from 'hooks/useTokenAsset'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const InfoBox = styled.div`
  border-radius: 0.75rem;
  border: 1px solid var(--Div-Stroke, #d8ecff);
  display: flex;
  padding: 1.25rem 1rem;
  align-items: center;
  width: 100%;
  gap: 0.75rem;
`

interface IPendingStakeViewProps {
  hash: string
  amountIn: BigNumber
  token0: string
  token1: string
  symbol: string
  decimals: number
  type: 'stake' | 'unstake'
  onBack: () => void
}

const StyledTokenIcon = styled(TokenIcon)`
  width: 1.125rem;
  height: 1.125rem;
`

export const PendingStakeView = ({
  hash,
  amountIn,
  symbol,
  decimals,
  token0,
  token1,
  type,
  onBack,
}: IPendingStakeViewProps) => {
  const token0Data = useTokenAsset(token0)
  const token1Data = useTokenAsset(token1)

  const [status, setStatus] = useState<'pending' | 'done'>('pending')

  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    onBack()
  }, [onBack])

  const text = useMemo(() => {
    if (type === 'stake') {
      return status === 'pending' ? t('Farm.pendingStake') : t('Farm.staked')
    }
    return status === 'pending' ? t('Farm.pendingUnstake') : t('Farm.unstaked')
  }, [status, t, type])

  return (
    <Stack spacing={2}>
      <TxStatusView hash={hash} onBack={handleClose} onStatusChange={setStatus}>
        <InfoBox>
          <span>{text}</span>
          <FlexContainer
            additionalStyles={{
              gap: '0.5rem',
              alignItems: 'center',
              justifyContent: 'flex-start',
              display: 'inline-flex',
              flex: 0,
            }}
          >
            <TokenRelativeBlock>
              <StyledTokenIcon
                src={token0Data?.icon}
                alt={symbol}
                onError={(e) => {
                  e.currentTarget.src = '/images/tokens/unknown.png'
                }}
                style={{ marginRight: '-0.25rem' }}
              />
              <StyledTokenIcon
                src={token1Data?.icon}
                onError={(e) => {
                  e.currentTarget.src = '/images/tokens/unknown.png'
                }}
              />
            </TokenRelativeBlock>
            <FlexContainer additionalStyles={{ flex: 1, gap: '0.25rem' }}>
              <span>
                <ScientificNumber value={formatUnits(amountIn, decimals)} />
              </span>
              <span> {symbol}</span>
            </FlexContainer>
          </FlexContainer>
        </InfoBox>
      </TxStatusView>
    </Stack>
  )
}
