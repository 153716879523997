import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import { formatDecimal } from 'utils/numberWithCommas'

import { BalanceFormatter, FormatOptions } from './types'

const DEBUG_TAG = 'USE_BALANCE_FORMATTER'

/**
 * Convert scientific notation to decimal string
 */
const fromScientific = (num: number | string): string => {
  const str = num.toString()
  if (!/\d+\.?\d*e[+-]*\d+/i.test(str)) {
    return str
  }

  const [base, exponent] = str.toLowerCase().split('e')
  const exp = parseInt(exponent)
  const baseNum = parseFloat(base)

  // Convert to regular number string
  return baseNum.toLocaleString('fullwide', {
    useGrouping: false,
    maximumFractionDigits: 20,
  })
}

/**
 * Hook for formatting token balances
 */
export const useBalanceFormatter = (): BalanceFormatter => {
  return useMemo(
    () => ({
      formatBalance: (amount: BigNumber, decimals: number): string => {
        try {
          if (!amount) return '0'
          return formatDecimal(amount, 2, decimals)
        } catch (error) {
          console.error(`[${DEBUG_TAG}:formatBalance] Error:`, {
            error,
            amount: amount.toString(),
            decimals,
          })
          return '0'
        }
      },

      parseBalance: (value: string, decimals: number): BigNumber => {
        try {
          if (!value) return BigNumber.from(0)

          // Convert scientific notation
          const decimalStr = fromScientific(value)

          // Remove any non-numeric characters except decimal point
          const cleanValue = decimalStr.replace(/[^\d.]/g, '')

          // Convert to wei
          const [whole, fraction = ''] = cleanValue.split('.')
          const paddedFraction = fraction.padEnd(decimals, '0')
          const wei = whole + paddedFraction

          return BigNumber.from(wei)
        } catch (error) {
          console.error(`[${DEBUG_TAG}:parseBalance] Error:`, {
            error,
            value,
            decimals,
          })
          return BigNumber.from(0)
        }
      },

      formatWithOptions: (amount: BigNumber, options: FormatOptions): string => {
        try {
          if (!amount) return '0'

          const { decimals = 18, compact = false, maximumFractionDigits = 2, showTrailingZeros = false } = options

          let formatted = formatDecimal(amount, maximumFractionDigits, decimals)

          // Handle compact notation
          if (compact) {
            const num = parseFloat(formatted)
            if (num >= 1e9) {
              return (num / 1e9).toFixed(1) + 'B'
            }
            if (num >= 1e6) {
              return (num / 1e6).toFixed(1) + 'M'
            }
            if (num >= 1e3) {
              return (num / 1e3).toFixed(1) + 'K'
            }
          }

          // Remove trailing zeros if needed
          if (!showTrailingZeros) {
            formatted = formatted.replace(/\.?0+$/, '')
          }

          return formatted
        } catch (error) {
          console.error(`[${DEBUG_TAG}:formatWithOptions] Error:`, {
            error,
            amount: amount.toString(),
            options,
          })
          return '0'
        }
      },
    }),
    []
  )
}
