import { useTokenAsset } from 'hooks/useTokenAsset'

import { BaseTokenBadge } from '../base/TokenBadge'
import { TokenIcon, TokenRelativeBlock } from '../styles'
import { ISwapToken } from './types'

interface SwapTokenBadgeProps {
  token: ISwapToken
  onClick: () => void
  withoutOptions?: boolean
  showChainIcon?: boolean
}

export const SwapTokenBadge = ({ token, onClick, withoutOptions, showChainIcon }: SwapTokenBadgeProps) => {
  const tokenData = useTokenAsset(token.address ? token.address : token.symbol)

  return (
    <BaseTokenBadge
      token={token}
      onClick={onClick}
      withoutOptions={withoutOptions}
      renderTokenIcon={(token) => (
        <TokenRelativeBlock>
          <TokenIcon
            src={tokenData?.icon}
            alt={token.symbol}
            onError={(e) => {
              e.currentTarget.src = '/images/tokens/unknown.png'
            }}
          />
          {tokenData?.chainIcon && showChainIcon && (
            <TokenIcon
              src={tokenData?.chainIcon}
              alt={token.symbol}
              onError={(e) => {
                e.currentTarget.src = '/images/tokens/unknown.png'
              }}
              style={{ position: 'absolute', bottom: 0, right: '-4px', width: '16px', height: '16px' }}
            />
          )}
        </TokenRelativeBlock>
      )}
    />
  )
}
