import { ILPToken } from 'components/blocks/AmountInput/lp/types'
import { formatUnits } from 'ethers/lib/utils'
import { useMemo } from 'react'
import { Address } from 'viem'

import { useUserFarmsRewards } from './useUserFarmsRewards'

interface FarmReward extends Omit<ILPToken, 'farmAddress'> {
  farmAddress: Address
  reward: bigint
}

interface ClaimState {
  isLoading: boolean
  hasRewards: boolean
  rewards: FarmReward[]
  totalReward: string
  refetch: () => Promise<void>
}

export const useClaimState = (): ClaimState => {
  const { rewards, isLoading, refetch } = useUserFarmsRewards()

  const totalReward = useMemo(() => {
    if (isLoading || !rewards) return '0'
    const sum = rewards.reduce((acc, reward) => acc + reward.reward, 0n)
    return formatUnits(sum.toString(), 18)
  }, [rewards, isLoading])

  // Data is not ready if we're loading or if rewards haven't been fetched yet
  const isDataReady = !isLoading && rewards !== null

  // Initial state when rewards are loading or not ready
  if (!isDataReady) {
    return {
      isLoading: true,
      hasRewards: false,
      rewards: [],
      totalReward: '0',
      refetch,
    }
  }

  // After loading is complete and data is ready
  return {
    isLoading: false,
    hasRewards: rewards.length > 0 && +totalReward > 0,
    rewards,
    totalReward,
    refetch,
  }
}
