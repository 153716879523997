import { Unicon } from 'components/Unicon'
import { Connection } from 'connection/types'
import styled from 'styled-components'
import { flexColumnNoWrap } from 'theme/styles'

const IconWrapper = styled.div<{ size?: number }>`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  width: 1.5rem;
  height: 1.5rem;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 1rem;
    height: 1rem;
  `};
`

export const StatusIcon = ({
  account,
  connection,
  size = 16,
}: {
  account: string
  connection: Connection
  size?: number
}) => {
  if (!account) {
    return null
  }

  // Get wallet icon from provider info
  const providerIcon = connection.getProviderInfo().icon

  return (
    <IconWrapper size={size} data-testid="StatusIconRoot">
      {providerIcon ? <Icon src={providerIcon} /> : <Unicon address={account} size={size} />}
    </IconWrapper>
  )
}
