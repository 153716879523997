import { useErc20Contract } from 'constants/app-contracts'
import { BigNumber } from 'ethers'
import { useActiveWeb3React } from 'hooks/web3'
import { useEffect, useState } from 'react'
import { ZERO } from 'utils/isZero'

export const useDirectTokenBalance = (tokenAddress?: string) => {
  const [balance, setBalance] = useState<BigNumber>(ZERO)
  const { account } = useActiveWeb3React()
  const contract = useErc20Contract(tokenAddress)

  useEffect(() => {
    if (!contract || !account) return

    const fetchBalance = async () => {
      try {
        const balance = await contract.balanceOf(account)
        setBalance(balance)
      } catch (error) {
        console.error('[DirectTokenBalance] Failed to fetch balance:', error)
      }
    }

    fetchBalance()
    const interval = setInterval(fetchBalance, 10000)

    return () => clearInterval(interval)
  }, [contract, account])

  return balance
}
