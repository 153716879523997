export const FARMS_MAP: Record<string, { lpSymbol: string; lpAddress: string }> = {
  '0x89a393eCC9F831c93C7a7Fecb396053F6C40aA7c': {
    lpSymbol: 'USDC/WXFI',
    lpAddress: '0x8D1afcee42c72FB3344e87a5Abc5a1B7BbD20cb1',
  },
  '0x9CB6e2F893D5D343782Cec174D4C4F68e85bB54C': {
    lpSymbol: 'WETH/WXFI',
    lpAddress: '0xbBf79B4B797e13212E9D8FcbF1E8c1658a2b7407',
  },
  '0x76729f36fFAA8a6DE8f53deFa6eD5ce50B036bf3': {
    lpSymbol: 'USDT/WXFI',
    lpAddress: '0xCB1d21b2059c01A3C6a5879f7D68016F1BEEF9fE',
  },
  '0x3DDAcf4DC283Ed843101d37fdA4448231015aECc': {
    lpSymbol: 'WBTC/WXFI',
    lpAddress: '0xD0ac6C077213F80e166d95f32314F2499523f079',
  },
  '0xA5f27A0123b3177f66EAbCa3a983ce185f393b7c': {
    lpSymbol: 'FOMO/XFI',
    lpAddress: '0xf040C889e7639C54cfd1d53C11bc2cF27FEFB54D',
  },
} as const
