import AngleDownIcon from '../../../../assets/svg/angle-down.svg'
import { AngleDown, TokenBadgeBox, TokenBadgeInfo, TokenIcon, TokenRelativeBlock, TokenSymbol } from '../styles'
import { BaseToken } from './types'

export interface BaseTokenBadgeProps<T extends BaseToken> {
  token: T
  onClick: () => void
  withoutOptions?: boolean
  showChainIcon?: boolean
  renderTokenIcon?: (token: T) => React.ReactNode
  renderSymbol?: (token: T) => React.ReactNode
}

export const BaseTokenBadge = <T extends BaseToken>({
  token,
  onClick,
  withoutOptions = false,
  showChainIcon = false,
  renderTokenIcon,
  renderSymbol,
}: BaseTokenBadgeProps<T>) => {
  return (
    <TokenBadgeBox withoutOptions={withoutOptions} onClick={!withoutOptions ? onClick : undefined}>
      <TokenBadgeInfo>
        {renderTokenIcon ? (
          renderTokenIcon(token)
        ) : (
          <TokenRelativeBlock>
            <TokenIcon src="/images/tokens/unknown.png" alt={token.symbol} />
          </TokenRelativeBlock>
        )}
        {renderSymbol ? renderSymbol(token) : <TokenSymbol>{token.symbol}</TokenSymbol>}
      </TokenBadgeInfo>
      {withoutOptions ? null : <AngleDown src={AngleDownIcon} alt="arrow" />}
    </TokenBadgeBox>
  )
}
