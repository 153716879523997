import { BigNumber } from 'ethers'
import { useLiquidityRate } from 'pages/Swap/hooks/prices/api/useLiquidityRate'
import { useCallback, useEffect, useState } from 'react'
import { ZERO } from 'utils/isZero'
import { Address } from 'viem'

interface UseProportionalLiquidityProps {
  token0?: Address
  token1?: Address
  decimals0: number
  decimals1: number
}

interface UseProportionalLiquidityResult {
  amount0?: BigNumber
  amount1?: BigNumber
  setAmount0: (amount: BigNumber | undefined) => void
  setAmount1: (amount: BigNumber | undefined) => void
  isCalculating: boolean
  error?: string
}

export function useProportionalLiquidity({
  token0,
  token1,
  decimals0,
  decimals1,
}: UseProportionalLiquidityProps): UseProportionalLiquidityResult {
  const [amount0, setAmount0Internal] = useState<BigNumber>()
  const [amount1, setAmount1Internal] = useState<BigNumber>()

  // Track which input was last modified to determine calculation direction
  const [lastModifiedInput, setLastModifiedInput] = useState<'input0' | 'input1'>()

  // Convert amounts to proper decimal string for API
  const amount0String = amount0?.toString() || '0'
  const amount1String = amount1?.toString() || '0'

  const shouldCalculate0to1 = Boolean(lastModifiedInput === 'input0' && amount0 && !amount0.eq(ZERO))
  const shouldCalculate1to0 = Boolean(lastModifiedInput === 'input1' && amount1 && !amount1.eq(ZERO))

  // Setup route queries for both directions
  const { result: route0to1, pending: pending0to1 } = useLiquidityRate(
    shouldCalculate0to1 ? token0 : undefined,
    shouldCalculate0to1 ? token1 : undefined,
    amount0String
  )

  const { result: route1to0, pending: pending1to0 } = useLiquidityRate(
    shouldCalculate1to0 ? token1 : undefined,
    shouldCalculate1to0 ? token0 : undefined,
    amount1String
  )

  // Handle setting amount0 with tracking
  const setAmount0 = useCallback((amount: BigNumber | undefined) => {
    if (!amount || amount.eq(ZERO)) {
      setAmount0Internal(undefined)
      setAmount1Internal(undefined)
    } else {
      setAmount0Internal(amount)
    }
    setLastModifiedInput('input0')
  }, [])

  // Handle setting amount1 with tracking
  const setAmount1 = useCallback((amount: BigNumber | undefined) => {
    if (!amount || amount.eq(ZERO)) {
      setAmount0Internal(undefined)
      setAmount1Internal(undefined)
    } else {
      setAmount1Internal(amount)
    }
    setLastModifiedInput('input1')
  }, [])

  // Effect to update the other amount based on route results
  useEffect(() => {
    if (shouldCalculate0to1 && route0to1?.amountOut && !pending0to1) {
      try {
        const parsedAmount = BigNumber.from(route0to1.amountOut)
        // Only update if value actually changes
        if (!amount1?.eq(parsedAmount)) {
          setAmount1Internal(parsedAmount)
        }
      } catch (e) {
        console.error('Failed to parse amount out:', e)
      }
    } else if (shouldCalculate1to0 && route1to0?.amountOut && !pending1to0) {
      try {
        const parsedAmount = BigNumber.from(route1to0.amountOut)
        // Only update if value actually changes
        if (!amount0?.eq(parsedAmount)) {
          setAmount0Internal(parsedAmount)
        }
      } catch (e) {
        console.error('Failed to parse amount out:', e)
      }
    }
  }, [
    route0to1?.amountOut, // Only depend on specific property
    route1to0?.amountOut, // Only depend on specific property
    pending0to1,
    pending1to0,
    shouldCalculate0to1,
    shouldCalculate1to0,
    amount0,
    amount1,
  ])

  const error = route0to1?.error || route1to0?.error

  return {
    amount0,
    amount1,
    setAmount0,
    setAmount1,
    isCalculating: (shouldCalculate0to1 && pending0to1) || (shouldCalculate1to0 && pending1to0),
    error,
  }
}
