import { useTranslation } from 'react-i18next'

import { BaseAmountInput } from '../base/BaseAmountInput'
import { TokenListModal } from '../base/TokenListModal'
import { SwapTokenBadge } from './SwapTokenBadge'
import { SwapTokenListItem } from './SwapTokenListItem'
import { ISwapToken, SwapAmountInputProps } from './types'

export const SwapAmountInput = (props: SwapAmountInputProps) => {
  const { t } = useTranslation()

  return (
    <BaseAmountInput<ISwapToken>
      {...props}
      customComponents={{
        tokenBadge: ({ token, onClick }) => (
          <SwapTokenBadge
            token={token}
            onClick={onClick}
            withoutOptions={!props.rightTokenOptions?.length}
            showChainIcon={props.showChainIcon}
          />
        ),
        tokenList: ({ isOpen, onDismiss, tokens, selectedToken, onSelect }) => (
          <TokenListModal
            isOpen={isOpen}
            onDismiss={onDismiss}
            tokens={tokens}
            selectedToken={selectedToken}
            onSelect={onSelect}
            title={t('tokenSelector.title')}
            searchPlaceholder={t('tokenSelector.search')}
            showSearch={props.showSearch}
            renderToken={({ token, onClick, selected }) => (
              <SwapTokenListItem
                token={token}
                onClick={onClick}
                selected={selected}
                showChainIcon={props.showChainIcon}
              />
            )}
          />
        ),
        ...props.customComponents,
      }}
    />
  )
}

// Export with balance for backward compatibility
export const SwapAmountInputWithBalance = SwapAmountInput
