import { Stack } from '@mui/material'
import { FlexContainer } from 'components/Flex'
import { WALLET_VIEWS } from 'constants/wallet'
import { useDisconnectWallet } from 'hooks/useDisconnectWallet'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import { useActiveWeb3React } from 'hooks/web3'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import { shortenString } from 'utils'

import { ReactComponent as MeatamaskIcon } from '../../assets/svg/metamask.svg'

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  svg {
    width: 100%;
    height: 100%;
  }
`

const MenuItem = styled.li`
  display: flex;
  align-items: start;
  justify-content: space-between;
  transition: background-color 0.2s ease-in;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 14.375rem;
  padding: 1.25rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 1.25rem;
  border: 1px solid ${({ theme }) => theme.text12};
  &:hover {
    cursor: pointer;
    background-color: var(--White, #fff);
  }

  ${({ theme }) => theme.mediaWidth.upToTablet`
    width: 100%;
    padding: 1.25rem;
  `}
`

const AccountName = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text100};
`

const AccountType = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.text60};
  margin-top: 0.25rem;
`

const DisconnectButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.red};
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0;
  margin-top: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-in-out;
`

interface AccountDetailsProps {
  toggleWalletModal: () => void
  isOpen?: boolean
  setWalletView: (view: (typeof WALLET_VIEWS)[keyof typeof WALLET_VIEWS]) => void
}

export default function AccountDetails({ isOpen, toggleWalletModal, setWalletView }: AccountDetailsProps) {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const { disconnect } = useDisconnectWallet()
  const isMobile = useIsMobileDevice()
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        toggleWalletModal()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, toggleWalletModal])

  if (!isOpen) return null

  return (
    <ModalOverlay>
      <div ref={modalRef}>
        <Stack spacing="1rem">
          <MenuItem>
            <FlexContainer>
              <IconWrapper>
                <MeatamaskIcon />
              </IconWrapper>
              <div>
                <AccountName>{shortenString(account ?? '')}</AccountName>
                <AccountType>{t('metamask')}</AccountType>
              </div>
            </FlexContainer>
            <DisconnectButton onClick={disconnect}>{t('disconnect')}</DisconnectButton>
          </MenuItem>
        </Stack>
      </div>
    </ModalOverlay>
  )
}
