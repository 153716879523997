import { TokenSymbol } from 'components/blocks/AmountInput/TokenSymbol'

const DEBUG_TAG = 'TOKEN_UTILS'

const NATIVE_TOKEN_ADDRESS = '0x0000000000000000000000000000000000000000'

/**
 * Converts native token addresses to their wrapped versions when needed
 * Used for routing and contract interactions
 * @param tokenIn Input token address or symbol
 * @param tokenOut Output token address or symbol
 * @param wxfiAddress Wrapped XFI address
 * @returns Object with wrapped versions of tokens
 */
export const getWrappedTokenAddresses = (tokenIn?: string, tokenOut?: string, wxfiAddress?: string) => {
  if (!wxfiAddress) {
    console.warn(`[${DEBUG_TAG}] WXFI address not provided`)
    return { targetIn: tokenIn, targetOut: tokenOut }
  }

  const isNativeToken = (token?: string) =>
    token?.toLowerCase() === TokenSymbol.xfi.toLowerCase() ||
    token?.toLowerCase() === NATIVE_TOKEN_ADDRESS.toLowerCase()

  const targetIn = isNativeToken(tokenIn) ? wxfiAddress : tokenIn
  const targetOut = isNativeToken(tokenOut) ? wxfiAddress : tokenOut

  return { targetIn, targetOut }
}
