import { connections } from 'connection'
import { getPersistedConnectionMeta, setPersistedConnectionMeta } from 'connection/meta'
import { useActiveWeb3React } from 'hooks/web3'
import { useEffect } from 'react'

export function usePersistentConnection() {
  const { account, connector } = useActiveWeb3React()

  // Try to restore connection on mount
  useEffect(() => {
    const meta = getPersistedConnectionMeta()
    if (meta?.type && !account) {
      const savedConnection = connections.find((connection) => connection.type === meta.type)
      if (savedConnection) {
        // Try to restore the connection
        const activatePromise = savedConnection.connector.activate()
        if (activatePromise instanceof Promise) {
          activatePromise.catch((error: Error) => {
            console.error('Failed to restore connection:', error)
          })
        }
      }
    }
  }, [account])

  // Save connection info when connected
  useEffect(() => {
    if (account && connector) {
      const connection = connections.find((connection) => connection.connector === connector)
      if (connection) {
        setPersistedConnectionMeta({
          type: connection.type,
          address: account,
        })
      }
    }
  }, [account, connector])
}
