import { Token } from '../../types/tokens'

const DEBUG_TAG = 'TOKEN_UTILS'

/**
 * Check if token is native XFI
 */
export const isNativeXfi = (addressOrSymbol: string): boolean => {
  return addressOrSymbol?.toLowerCase() === 'xfi'
}

/**
 * Check if token is wrapped XFI (WXFI)
 */
export const isWrappedXfi = (token: Token): boolean => {
  return token.symbol?.toLowerCase() === 'wxfi'
}

/**
 * Find token by address or symbol
 */
export const findTokenByAddressOrSymbol = (tokens: Token[], addressOrSymbol: string): Token | undefined => {
  // For native XFI, find XFI model (not WXFI)
  if (isNativeXfi(addressOrSymbol)) {
    return tokens.find((t) => t.symbol?.toLowerCase() === 'xfi' && !isWrappedXfi(t))
  }

  // For other tokens, find by address
  return tokens.find((t) => t.address?.toLowerCase() === addressOrSymbol.toLowerCase())
}

/**
 * Filter out token from list
 */
export const filterOutToken = (tokens: Token[], addressOrSymbol: string): Token[] => {
  if (isNativeXfi(addressOrSymbol)) {
    return tokens.filter((t) => t.symbol?.toLowerCase() !== 'xfi' || isWrappedXfi(t))
  }

  return tokens.filter((t) => t.address?.toLowerCase() !== addressOrSymbol.toLowerCase())
}
