import { useQuery } from '@tanstack/react-query'
import { API_URL } from 'api/api'
import { useEffect, useMemo, useRef, useState } from 'react'

import { SwapRouteResponse, SwapRouteResult } from '../types/api.types'

const DEBUG_TAG = 'USE_SWAP_ROUTE'
const DEBOUNCE_TIME = 500 // ms

const fetchSwapRoute = async (url: string): Promise<SwapRouteResponse> => {
  if (!url) {
    throw new Error('Invalid tokens provided')
  }

  const response = await fetch(url)
  const data = await response.json()

  if ('detail' in data) {
    throw new Error(data.detail)
  }

  if (!data.path || !Array.isArray(data.path) || !data.amount_out) {
    console.error(`[${DEBUG_TAG}:ValidationError]`, data)
    throw new Error('Invalid API response')
  }

  return data
}

/**
 * Hook for fetching swap route from API using react-query
 */
export const useSwapRoute = (
  token0?: string,
  token1?: string,
  amountIn = '1'
): {
  result?: SwapRouteResult
  pending: boolean
} => {
  // Используем debouncedAmountIn для запросов
  const [debouncedAmountIn, setDebouncedAmountIn] = useState(amountIn)
  const timerRef = useRef<NodeJS.Timeout>()
  const lastAmountRef = useRef(amountIn)

  // Обновляем debouncedAmountIn с задержкой
  useEffect(() => {
    // Игнорируем очень маленькие изменения значений
    if (Math.abs(+amountIn - +lastAmountRef.current) < 0.000001) {
      return
    }

    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      setDebouncedAmountIn(amountIn)
      lastAmountRef.current = amountIn
    }, DEBOUNCE_TIME)

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [amountIn])

  const url = useMemo(() => {
    if (!token0 || !token1) {
      return ''
    }

    return `${API_URL}swap/route?token0=${token0}&token1=${token1}&amount_in=${debouncedAmountIn?.toString()}`
  }, [token0, token1, debouncedAmountIn])

  const { data, isLoading, error } = useQuery({
    queryKey: ['swapRoute', token0, token1, debouncedAmountIn],
    queryFn: () => fetchSwapRoute(url),
    enabled: !!url && +debouncedAmountIn > 0,
    staleTime: 30000, // Consider data stale after 30 seconds
    gcTime: 60000, // Keep data in cache for 1 minute
  })

  const result = useMemo((): SwapRouteResult | undefined => {
    if (error) {
      return {
        path: undefined,
        error: error instanceof Error ? error.message : 'Unknown error',
        amountOut: undefined,
      }
    }

    if (!data) return undefined

    return {
      path: data.path,
      error: undefined,
      amountOut: data.amount_out,
      priceImpact: data.price_impact,
    }
  }, [data, error])

  return {
    result,
    pending: isLoading,
  }
}
