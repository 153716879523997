import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { API_URL } from 'api/api'
import { Address } from 'viem'

interface Token {
  address: Address
  decimals: number
  name: string
  symbol: string
  is_blacklisted: boolean
  chain_id: number
}

interface Pair {
  address: Address
  token0: Token
  token1: Token
  weight: number
  is_blacklisted: boolean
  reserve0: number
  reserve1: number
  blockTimestampLast: number
  chain_id: number
}

interface LiquidityBalanceResponse {
  pair: Pair
  LP_token_amount: number
  token0_provided: number
  token0_provided_usd: number
  token1_provided: number
  token1_provided_usd: number
}

const DEBUG_TAG = 'USE_LIQUIDITY_BALANCE'

const fetchLiquidityBalance = async (
  token0: Address,
  token1: Address,
  userAddress: Address
): Promise<LiquidityBalanceResponse> => {
  try {
    const response = await fetch(
      `${API_URL}liquidity/user/balance?token0=${token0}&token1=${token1}&user=${userAddress}`
    )

    if (!response.ok) {
      throw new Error(`Failed to fetch liquidity balance: ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error(`[${DEBUG_TAG}] Error fetching liquidity balance:`, error)
    throw error
  }
}

/**
 * Hook for fetching user's liquidity balance for token pair
 * @param token0 - The address of the first token
 * @param token1 - The address of the second token
 * @param userAddress - The address of the user
 * @param options - Additional options for the query
 */
export const useLiquidityBalance = (
  token0: Address,
  token1: Address,
  userAddress: Address,
  options: Omit<UseQueryOptions<LiquidityBalanceResponse, Error, LiquidityBalanceResponse>, 'queryKey' | 'queryFn'> = {}
) => {
  return useQuery({
    queryKey: ['liquidity', 'balance', token0, token1, userAddress],
    queryFn: () => fetchLiquidityBalance(token0, token1, userAddress),
    retry: false,
    staleTime: 30000, // Consider data stale after 30 seconds
    gcTime: 60000, // Keep data in cache for 1 minute
    enabled: Boolean(token0 && token1 && userAddress),
    ...options,
  })
}
