import { Currency } from '@uniswap/sdk-core'
import { TxTemplateTypes } from 'constants/transactions'
import { useTokenContract } from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import JSBI from 'jsbi'
import { useCallback, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { ZERO } from 'utils/isZero'

import { useTokenAllowanceListener } from './useTokenAllowanceListener'

export function useResetApproval(currency: Currency | undefined | null, spender?: string) {
  const { chainId } = useActiveWeb3React()
  const token = currency?.isToken ? currency : undefined
  const [isPending, setIsPending] = useState(false)

  const tokenContract = useTokenContract(token?.address)
  const addTransaction = useTransactionAdder()
  const { allowance } = useTokenAllowanceListener(currency, spender)

  const resetApproval = useCallback(async (): Promise<void> => {
    if (!chainId) {
      console.error('no chainId')
      return
    }

    if (!token) {
      console.error('no token')
      return
    }

    if (!tokenContract) {
      console.error('tokenContract is null')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    if (!allowance || JSBI.equal(allowance, JSBI.BigInt(0))) {
      console.error('no allowance to reset')
      return
    }

    try {
      setIsPending(true)
      const estimatedGas = await tokenContract.estimateGas.approve(spender, ZERO)

      const response = await tokenContract.approve(spender, ZERO, {
        gasLimit: calculateGasMargin(chainId, estimatedGas),
      })

      addTransaction(response, {
        summary: 'Reset approval for ' + currency?.symbol,
        approval: { tokenAddress: token.address, spender },
        type: TxTemplateTypes.Approved,
      })

      await response.wait()
    } catch (error) {
      console.debug('Failed to reset token approval', error)
      throw error
    } finally {
      setIsPending(false)
    }
  }, [chainId, token, tokenContract, spender, allowance, currency?.symbol, addTransaction])

  return {
    resetApproval,
    hasAllowance: allowance && !JSBI.equal(allowance, JSBI.BigInt(0)),
    isPending,
  }
}
