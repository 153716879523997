import { Button } from 'components'
import { ConfirmInWalletBlock } from 'components/Approval/ApproveTx'
import { SwapAmountInput } from 'components/blocks/AmountInput/swap/SwapAmountInput'
import { TokenSymbol } from 'components/blocks/AmountInput/TokenSymbol'
import { AutoColumn } from 'components/Column'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import { WarningBlock } from 'components/WarningBlock/WarningBlock'
import { useWXFIAddress } from 'constants/app-contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { BigNumber } from 'ethers'
import { useActiveWeb3React } from 'hooks/web3'
import { useTokenBalance } from 'lib/hooks/balances'
import { useApprovalSteps } from 'lib/hooks/useApprovalSteps'
import { useLastChangedInput } from 'lib/hooks/useLastChangedInput'
import { useProportionalLiquidity } from 'lib/hooks/useProportionalLiquidity'
import { useAddLiquiditySwap, useLiquidityTokens } from 'pages/Swap/utils'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplicationModal } from 'state/application/actions'
import { useToggleModal } from 'state/application/hooks'
import styled from 'styled-components'
import { ZERO } from 'utils/isZero'
import { Address } from 'viem'

import { AddLiquidityInfo, useLockedInPool } from '../InfoBox'
import { AddLiquidityProgress } from './AddLIqudityProgress'
import { ApproveLiquidity } from './components/ApproveLiquidity'
import { PendingAddLiquidityView } from './PendingView'

const WarningBlockStyled = styled(WarningBlock)`
  margin-bottom: 1rem;
`

export function Form({ inputToken0, inputToken1 }: { inputToken0?: string; inputToken1?: string }) {
  const toggle = useToggleModal(ApplicationModal.ADD_LIQUIDITY)
  const { lastChangedInput, setLastChangedInput } = useLastChangedInput()
  const {
    tokenIn,
    tokenOut,
    amountIn,
    amountOut,
    setPendingTx,
    noValue,
    pendingTx,
    tokenInModel,
    tokenOutModel,
    setAmountIn: setAmountInOriginal,
    setAmountOut: setAmountOutOriginal,
    tokensInList,
    setTokenIn,
    tokensOutList,
    loadingAssets,
    setTokenOut,
    loadingReserves,
    reserves,
    pair,
  } = useLiquidityTokens(inputToken0, inputToken1)

  const {
    amount0,
    amount1,
    setAmount0,
    setAmount1,
    isCalculating,
    error: routeError,
  } = useProportionalLiquidity({
    token0: tokenIn as Address,
    token1: tokenOut as Address,
    decimals0: tokenInModel.decimals,
    decimals1: tokenOutModel.decimals,
  })

  // Sync amounts from proportional hook to main state
  useEffect(() => {
    if (amount0 !== undefined) {
      setAmountInOriginal(amount0)
    }
  }, [amount0, setAmountInOriginal])

  useEffect(() => {
    if (amount1 !== undefined) {
      setAmountOutOriginal(amount1)
    }
  }, [amount1, setAmountOutOriginal])

  const wxfiToken = useWXFIAddress()

  const isWXfiIn = inputToken0 === wxfiToken
  const isWXfiOut = inputToken1 === wxfiToken

  const tokensFirstList = useMemo(() => {
    return isWXfiIn
      ? tokensInList.filter((item) => {
          return (
            item.address?.toLowerCase() === wxfiToken ||
            item.address?.toLowerCase() === inputToken0?.toLowerCase() ||
            (item.address as string)?.toLowerCase() === TokenSymbol.xfi.toLowerCase()
          )
        })
      : []
  }, [inputToken0, isWXfiIn, tokensInList, wxfiToken])

  const tokensSecondList = useMemo(() => {
    return isWXfiOut
      ? tokensOutList.filter((item) => {
          return (
            item.address?.toLowerCase() === wxfiToken ||
            item.address?.toLowerCase() === inputToken1?.toLowerCase() ||
            (item.address as string) === ZERO_ADDRESS
          )
        })
      : []
  }, [inputToken1, isWXfiOut, tokensOutList, wxfiToken])

  const { t } = useTranslation()

  const { account } = useActiveWeb3React()

  const { pending, action, isError, txInfo, calledWallet, path, loadingPath, isXfiIn, isXfiOut } = useAddLiquiditySwap(
    tokenIn,
    tokenOut,
    amountIn,
    amountOut,
    setPendingTx
  )

  const { steps: approveSteps, approvalStates } = useApprovalSteps({
    tokenInAddress: tokenInModel.address,
    tokenInSymbol: tokenInModel.symbol,
    tokenInAmount: amountIn,
    tokenOutAddress: tokenOutModel.address,
    tokenOutSymbol: tokenOutModel.symbol,
    tokenOutAmount: amountOut,
    isXfiIn,
    isXfiOut,
  })

  const setInputFirst = useCallback(
    (v?: BigNumber) => {
      setAmount0(v)
      setLastChangedInput('first')
    },
    [setAmount0, setLastChangedInput]
  )

  const setInputSecond = useCallback(
    (v?: BigNumber) => {
      setAmount1(v)
      setLastChangedInput('second')
    },
    [setAmount1, setLastChangedInput]
  )

  const data = useLockedInPool(pair, tokenInModel.address, tokenOutModel.address, ZERO)

  // Get balances for both tokens
  const token0Balance = useTokenBalance({
    address: tokenInModel.address as Address,
    symbol: tokenInModel.symbol,
    decimals: tokenInModel.decimals,
  })

  const token1Balance = useTokenBalance({
    address: tokenOutModel.address as Address,
    symbol: tokenOutModel.symbol,
    decimals: tokenOutModel.decimals,
  })

  if (pendingTx) {
    return (
      <AutoColumn
        gap=".5rem"
        style={{
          margin: '1.5rem 0',
        }}
      >
        <PendingAddLiquidityView
          onBack={() => {
            setPendingTx('')
            toggle()
          }}
          hash={pendingTx}
          token0={tokenInModel}
          token1={tokenOutModel}
          lpAddress={pair}
          amount0={amountIn}
          amount1={amountOut}
        />
      </AutoColumn>
    )
  }

  const ConfirmBlock = (
    <ConfirmInWalletBlock calledWallet={calledWallet}>
      {noValue ? (
        <Button disabled={noValue}>{t('pools.enterAmount')}</Button>
      ) : (
        <Button
          onClick={action}
          disabled={
            isError ||
            loadingPath ||
            (amountIn && token0Balance?.raw && amountIn.gt(token0Balance.raw)) ||
            (amountOut && token1Balance?.raw && amountOut.gt(token1Balance.raw))
          }
        >
          <FormActionBtn
            pending={pending || loadingPath}
            labelActive={t('pools.addLiquidity')}
            labelInProgress={t('pools.adding')}
          />
        </Button>
      )}
    </ConfirmInWalletBlock>
  )

  return (
    <>
      <AutoColumn
        gap=".5rem"
        style={{
          margin: '1.5rem 0',
        }}
      >
        <AddLiquidityInfo data={data} token1={tokenInModel} token2={tokenOutModel} />

        <SwapAmountInput
          showBalanceRow={!!account}
          inputValue={amountIn}
          setInputValue={setInputFirst}
          disabled={loadingAssets || loadingReserves || (isCalculating && lastChangedInput === 'second')}
          decimals={tokenInModel.decimals}
          rightToken={tokenInModel}
          rightTokenOptions={tokensFirstList}
          onChangeRightToken={setTokenIn}
          balance={token0Balance?.raw}
          max={token0Balance?.raw}
        />

        <SwapAmountInput
          showBalanceRow={!!account}
          inputValue={amountOut}
          setInputValue={setInputSecond}
          disabled={loadingAssets || loadingReserves || (isCalculating && lastChangedInput === 'first')}
          decimals={tokenOutModel.decimals}
          rightToken={tokenOutModel}
          rightTokenOptions={tokensSecondList}
          onChangeRightToken={setTokenOut}
          balance={token1Balance?.raw}
          max={token1Balance?.raw}
        />
      </AutoColumn>

      {pair && pair === ZERO_ADDRESS && <WarningBlockStyled text={t('pools.pairDoesntExist')} />}

      {routeError && amountIn && !amountIn.isZero() ? (
        <WarningBlockStyled text={routeError} />
      ) : path?.Error && amountIn && !amountIn.isZero() ? (
        <WarningBlockStyled text={path?.Error} />
      ) : null}

      <ApproveLiquidity
        tokenIn={tokenIn}
        tokenOut={tokenOut}
        amountIn={amountIn}
        amountOut={amountOut}
        approvalStates={approvalStates}
      >
        {ConfirmBlock}
      </ApproveLiquidity>

      <AddLiquidityProgress steps={approveSteps} />
    </>
  )
}
