import { Button } from 'components'
import { Web3ConnectBtn } from 'components'
import { ConfirmInWalletBlock } from 'components/Approval/ApproveTx'
import { Dots } from 'components/Dots'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import { BigNumber } from 'ethers'
import { useTranslation } from 'react-i18next'
import { ZERO } from 'utils/isZero'
import { Address } from 'viem'

import { useTokenApprove } from '../../hooks/contracts/useTokenApprove'

interface SwapButtonProps {
  account?: string | null
  isCalculating: boolean
  loadingPath: boolean
  amountIn?: BigNumber
  pathError?: string
  tokenIn?: string
  pending: boolean
  calledWallet: boolean
  action: () => void
  isError: boolean
  txInfo?: any
  spenderAddress?: Address
}

export const SwapButton = ({
  account,
  isCalculating,
  loadingPath,
  amountIn,
  pathError,
  tokenIn,
  pending,
  calledWallet,
  action,
  isError,
  txInfo,
  spenderAddress,
}: SwapButtonProps) => {
  const { t } = useTranslation()

  const { approvalState, approve } = useTokenApprove(tokenIn as Address, spenderAddress, amountIn || ZERO)

  const needsApproval = approvalState === 'NOT_APPROVED'
  const isApproving = approvalState === 'PENDING' || approvalState === 'LOADING'

  const handleAction = () => {
    if (needsApproval) {
      approve()
    } else {
      action()
    }
  }

  const ConfirmBlock = (
    <ConfirmInWalletBlock calledWallet={calledWallet}>
      {!amountIn || amountIn.isZero() ? (
        <Button disabled={true}>{t('Swap.enterAmount')}</Button>
      ) : (
        <Button onClick={handleAction} disabled={isError || pending || isApproving}>
          <FormActionBtn
            pending={pending || isApproving}
            labelActive={needsApproval ? t('Common.approve') : t('Swap.swap')}
            labelInProgress={isApproving ? t('Common.approving') : t('Swap.swapInProgress')}
          />
        </Button>
      )}
    </ConfirmInWalletBlock>
  )

  if (!account) {
    return <Web3ConnectBtn as={Button} theme="dark" />
  }

  if (isCalculating || loadingPath) {
    return (
      <Button disabled>
        <Dots>{t('Swap.loadingBestPath')}</Dots>
      </Button>
    )
  }

  if (!amountIn || pathError) {
    return <Button disabled={!amountIn}>{t('Swap.enterAmount')}</Button>
  }

  return ConfirmBlock
}
