import { BigNumber } from 'ethers'

/**
 * Format a wei value to a human readable string
 * @param wei The wei value to format
 * @param decimals The number of decimals to use
 * @returns A formatted string
 */
export const formatWei = (wei: BigNumber | undefined | null, decimals = 18): string => {
  if (!wei) return '0'

  try {
    const divisor = BigNumber.from(10).pow(decimals)
    const beforeDecimal = wei.div(divisor)
    const afterDecimal = wei.mod(divisor)

    // Convert to string and pad with leading zeros
    const afterDecimalStr = afterDecimal.toString().padStart(decimals, '0')

    // Take only 6 digits after decimal
    const truncated = afterDecimalStr.slice(0, 6)

    // Remove trailing zeros
    const trimmed = truncated.replace(/0+$/, '')

    return trimmed ? `${beforeDecimal.toString()}.${trimmed}` : beforeDecimal.toString()
  } catch (error) {
    console.error('[FormatWei:Error]', {
      error,
      wei: wei.toString(),
      decimals,
    })
    return '0'
  }
}

/**
 * Parse a decimal string to wei
 * @param decimal The decimal string to parse
 * @param decimals The number of decimals to use
 * @returns A BigNumber in wei
 */
export const parseToWei = (decimal: string | undefined | null, decimals = 18): BigNumber => {
  if (!decimal) return BigNumber.from(0)

  try {
    const [whole = '0', fraction = ''] = decimal.split('.')
    const paddedFraction = fraction.padEnd(decimals, '0')
    const wei = whole + paddedFraction
    return BigNumber.from(wei)
  } catch (error) {
    console.error('[ParseToWei:Error]', {
      error,
      decimal,
      decimals,
    })
    return BigNumber.from(0)
  }
}
