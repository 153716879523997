import { useErc20Contract } from 'constants/app-contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { useMemo } from 'react'
import { useSingleCallResult } from 'state/multicall/hooks'

export const useERC20Symbol = (token?: string) => {
  const tokenContract = useErc20Contract(token)
  const result = useSingleCallResult(tokenContract, 'symbol')

  return useMemo(() => {
    if (token?.toUpperCase() === 'XFI' || token === ZERO_ADDRESS) {
      return {
        symbol: 'XFI',
        loading: false,
      }
    }

    return {
      symbol: result?.result?.[0],
      loading: result.loading,
    }
  }, [result, token])
}
