import { ILPToken } from 'components/blocks/AmountInput/lp/types'
import { FARMS_MAP } from 'constants/farms'
import { useEffect, useMemo, useState } from 'react'
import { Address } from 'viem'

import { useAvailablePairs } from '../pairs'

interface UseFarmLPTokensResult {
  lpTokens: ILPToken[]
  selectedToken?: string
  selectedLPToken?: ILPToken
  isLoading: boolean
  setSelectedToken: (address: string) => void
}

export function useFarmLPTokens(): UseFarmLPTokensResult {
  const { pairs } = useAvailablePairs()
  const [selectedToken, setSelectedToken] = useState<string>()
  const [isLoading, setIsLoading] = useState(true)

  const lpTokens = useMemo<ILPToken[]>(() => {
    if (!pairs) return []

    setIsLoading(true)

    const tokens = pairs
      .map((pair) => {
        // Find farm data for this LP token
        const farmData = Object.entries(FARMS_MAP).find(
          ([_, farm]) => farm.lpAddress.toLowerCase() === pair.address.toLowerCase()
        )

        if (!farmData) return null

        const token: ILPToken = {
          address: pair.address as Address,
          symbol: `${pair.token0.symbol}/${pair.token1.symbol}`,
          decimals: 18,
          token0: pair.token0.address as Address,
          token1: pair.token1.address as Address,
          earnedIncentives: '0.00',
          farmAddress: farmData[0] as Address,
        }
        return token
      })
      .filter((token): token is NonNullable<typeof token> => token !== null)

    setIsLoading(false)

    return tokens
  }, [pairs])

  // Automatically select first token when list is loaded
  useEffect(() => {
    if (lpTokens.length > 0 && !selectedToken) {
      setSelectedToken(lpTokens[0].address)
    }
  }, [lpTokens, selectedToken])

  // Get selected LP token
  const selectedLPToken = useMemo(() => {
    return lpTokens.find((token) => token.address === selectedToken)
  }, [lpTokens, selectedToken])

  return {
    lpTokens,
    selectedToken,
    selectedLPToken,
    isLoading,
    setSelectedToken,
  }
}
