import { ReactNode } from 'react'
import styled from 'styled-components'

import { BaseToken } from './types'

export const TokensListItemBox = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  background: ${({ selected }) => (selected ? 'rgba(216, 236, 255, 0.3)' : 'transparent')};

  border-radius: 12px;
  border: 1px solid var(--Light-blue, #eff4f9);
  background: var(--INPUT-Default, #f6f9fc);

  &:hover {
    border-radius: 12px;
    border: 1px solid #add7ff;
    background: var(--INPUT-Default, #f6f9fc);
  }
`

export const TokenInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

const CheckIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12L9.32824 16L18 8" stroke="#67DA8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export interface BaseTokenListProps<T extends BaseToken> {
  tokens: T[]
  selectedToken?: T
  onSelect: (token: T) => void
  searchValue?: string
  filterTokens?: (tokens: T[], search: string) => T[]
  renderToken: (props: { token: T; onClick: () => void; selected?: boolean }) => ReactNode
}

export const BaseTokenList = <T extends BaseToken>({
  tokens,
  selectedToken,
  onSelect,
  searchValue = '',
  filterTokens = (tokens, search) =>
    tokens.filter(
      (token) =>
        token.symbol.toLowerCase().includes(search.toLowerCase()) ||
        token.address.toLowerCase().includes(search.toLowerCase())
    ),
  renderToken,
}: BaseTokenListProps<T>) => {
  const filteredTokens = filterTokens(tokens, searchValue)

  return (
    <>
      {filteredTokens.map((token, index) => (
        <div key={token.address || index}>
          {renderToken({
            token,
            onClick: () => onSelect(token),
            selected: token.address
              ? selectedToken?.address?.toLowerCase() === token.address?.toLowerCase()
              : selectedToken?.symbol.toLowerCase() === token.symbol.toLowerCase(),
          })}
        </div>
      ))}
    </>
  )
}

interface BaseTokenListItemProps<T extends BaseToken> {
  token: T
  onClick: () => void
  selected?: boolean
  renderTokenContent: (token: T) => ReactNode
  renderRightContent?: (token: T, selected: boolean) => ReactNode
}

export const BaseTokenListItem = <T extends BaseToken>({
  token,
  onClick,
  selected,
  renderTokenContent,
  renderRightContent,
}: BaseTokenListItemProps<T>) => {
  return (
    <TokensListItemBox onClick={onClick} selected={selected}>
      <TokenInfo>{renderTokenContent(token)}</TokenInfo>
      {selected && <CheckIcon />}
      {renderRightContent?.(token, selected ?? false)}
    </TokensListItemBox>
  )
}
