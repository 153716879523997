import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import { ZERO } from 'utils/isZero'

import { TokenAmountDisplay } from '../../types/formatting'

const DEBUG_TAG = 'TOKEN_AMOUNT_DISPLAY'

/**
 * Hook for handling token amount display values
 * Manages conversion between display and raw values
 *
 * @param amount - Current amount in wei
 * @param decimals - Token decimals
 * @returns TokenAmountDisplay interface
 */
export const useTokenAmountDisplay = (
  amount: BigNumber | undefined,
  decimals: number | undefined
): TokenAmountDisplay => {
  return useMemo(() => {
    /**
     * Convert raw value (in wei) to display value
     */
    const toDisplay = (raw: BigNumber | undefined): BigNumber | undefined => {
      if (!raw || decimals === undefined) {
        return undefined
      }

      try {
        // Value is already in wei format, return as is
        return raw
      } catch (error) {
        console.error(`[${DEBUG_TAG}:toDisplay] Error:`, {
          error,
          raw: raw?.toString(),
          decimals,
        })
        return ZERO
      }
    }

    /**
     * Convert display value to raw value (in wei)
     */
    const toRaw = (display: BigNumber | undefined): BigNumber | undefined => {
      if (!display || decimals === undefined) {
        return undefined
      }

      try {
        // Value is already in wei format, return as is
        return display
      } catch (error) {
        console.error(`[${DEBUG_TAG}:toRaw] Error:`, {
          error,
          display: display?.toString(),
          decimals,
        })
        return ZERO
      }
    }

    return {
      displayValue: toDisplay(amount),
      rawValue: amount,
      toDisplay,
      toRaw,
    }
  }, [amount, decimals])
}
