import { useUniswapV2Factory, useWXFIAddress } from 'constants/app-contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { getWrappedTokenAddresses } from 'pages/Swap/utils/tokens'
import { useCallback, useEffect, useMemo, useState } from 'react'

// Hook for getting pair address with error handling
export const usePairAddress = (
  tokenIn?: string,
  tokenOut?: string
): {
  pair: string
  loading: boolean
  error: boolean
} => {
  const contract = useUniswapV2Factory()
  const wxfiAddress = useWXFIAddress()
  const [pairState, setPairState] = useState<{
    pair: string
    loading: boolean
    error: boolean
  }>({
    pair: ZERO_ADDRESS,
    loading: false,
    error: false,
  })

  // Validate contract
  const isContractValid = useMemo(() => {
    if (!contract) {
      console.error('🔍 [usePairAddress] Factory contract is not initialized')
      return false
    }
    if (!contract.address) {
      console.error('🔍 [usePairAddress] Factory contract address is missing')
      return false
    }
    return true
  }, [contract])

  const getAddresses = useMemo(() => {
    if (!tokenIn || !tokenOut) {
      return null
    }

    if (!isContractValid) {
      console.error('🔍 [usePairAddress] Contract validation failed')
      return null
    }

    const { targetIn, targetOut } = getWrappedTokenAddresses(tokenIn, tokenOut, wxfiAddress)

    // Validate addresses
    if (!targetIn || !targetOut || targetIn === ZERO_ADDRESS || targetOut === ZERO_ADDRESS) {
      console.error('🔍 [usePairAddress] Invalid token addresses:', { targetIn, targetOut })
      return null
    }

    return { targetIn, targetOut }
  }, [tokenIn, tokenOut, wxfiAddress, isContractValid])

  const fetchPairAddress = useCallback(async () => {
    if (!getAddresses || !contract) {
      return
    }

    try {
      setPairState((prev) => ({ ...prev, loading: true, error: false }))

      const { targetIn, targetOut } = getAddresses
      const pairAddress = await contract.getPair(targetIn, targetOut)

      setPairState({
        pair: pairAddress || ZERO_ADDRESS,
        loading: false,
        error: false,
      })
    } catch (error) {
      console.error('🔍 [usePairAddress] Failed to fetch pair:', error)
      setPairState({
        pair: ZERO_ADDRESS,
        loading: false,
        error: true,
      })
    }
  }, [contract, getAddresses])

  useEffect(() => {
    fetchPairAddress()
  }, [fetchPairAddress])

  return pairState
}
