import { BigNumber, utils } from 'ethers'

const DEBUG_TAG = 'AMOUNT_FORMATTER'

interface ValidationError {
  code: 'INVALID_FORMAT' | 'TOO_MANY_DECIMALS' | 'INVALID_NUMBER' | 'EMPTY_INPUT'
  message: string
}

// This hook handles formatting of amounts for input/output
export const useAmountInputFormatter = (
  decimals: number
): {
  formatInput: (value: BigNumber | undefined) => string
  formatOutput: (value: string | number) => { value?: BigNumber; error?: ValidationError }
} => {
  // Convert BigNumber (wei) to decimal string for display
  const formatInput = (value: BigNumber | undefined): string => {
    if (!value) return ''

    try {
      const formatted = utils.formatUnits(value, decimals)
      // Don't add decimal places for whole numbers
      if (formatted.includes('.')) {
        return formatted.replace(/\.?0+$/, '')
      }
      return formatted
    } catch (error) {
      console.error(`[${DEBUG_TAG}:formatInput] Error:`, {
        error,
        value: value.toString(),
        decimals,
      })
      return ''
    }
  }

  // Convert display value to BigNumber (wei)
  const formatOutput = (val: string | number): { value?: BigNumber; error?: ValidationError } => {
    // Handle empty input
    if (!val) {
      return {
        error: {
          code: 'EMPTY_INPUT',
          message: 'Input is empty',
        },
      }
    }

    try {
      // Normalize input: convert to string and replace comma with dot
      const strValue = (typeof val === 'number' ? val.toString() : val).replace(',', '.')

      if (!strValue || strValue.trim() === '') {
        return {
          error: {
            code: 'EMPTY_INPUT',
            message: 'Input is empty after normalization',
          },
        }
      }

      // Validate format using regex (only numbers and single decimal point)
      const validNumberRegex = /^\d*\.?\d*$/
      if (!validNumberRegex.test(strValue)) {
        return {
          error: {
            code: 'INVALID_FORMAT',
            message: 'Invalid number format',
          },
        }
      }

      // Check decimal places
      const parts = strValue.split('.')
      if (parts.length === 2 && parts[1].length > decimals) {
        return {
          error: {
            code: 'TOO_MANY_DECIMALS',
            message: `Maximum ${decimals} decimal places allowed`,
          },
        }
      }

      // Handle incomplete decimal input (e.g. "1." or "1,")
      if (strValue.endsWith('.') || strValue.endsWith(',')) {
        return { value: utils.parseUnits(strValue.slice(0, -1), decimals) }
      }

      // Parse the value while preserving the exact input format
      const parsedValue = utils.parseUnits(strValue, decimals)
      return { value: parsedValue }
    } catch (error) {
      console.error(`[${DEBUG_TAG}:formatOutput] Error:`, {
        error,
        input: val,
        decimals,
      })
      return {
        error: {
          code: 'INVALID_NUMBER',
          message: 'Failed to parse number',
        },
      }
    }
  }

  return {
    formatInput,
    formatOutput,
  }
}
