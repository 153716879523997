import { InputAdornment, Stack } from '@mui/material'
import walletSvg from 'assets/icons/wallet.svg'
import { ScientificNumber } from 'components/blocks/ScientificNumber'
import { GreyCard } from 'components/Card'
import { Box } from 'components/MUI'
import { RowBetween } from 'components/Row'
import { formatUnits } from 'ethers/lib/utils'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import { useAmountInputFormatter } from 'lib/hooks/useAmountInputFormatter'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TYPE } from 'theme/theme'
import { ZERO } from 'utils/isZero'

import {
  AdditionalInfo,
  AmountBalanceRow,
  AmountValueInput,
  BalanceValue,
  InputContainer,
  MaxButton,
  WalletIcon,
} from '../styles'
import { BaseAmountInputProps, BaseToken } from './types'

// Base generic component that can work with any token type that extends BaseToken
export const BaseAmountInput = <T extends BaseToken>(props: BaseAmountInputProps<T>) => {
  const { t } = useTranslation()
  const [isTokenListOpen, setIsTokenListOpen] = useState(false)

  const {
    loading,
    max,
    placeholder,
    rightTokenOptions,
    tokenBadgeVariant,
    onChangeRightToken,
    balance,
    decimals = 18,
    label,
    showSearch = true,
    showChainIcon = false,
    formatter,
    walletIcon,
    showBalanceRow = true,
    validateBalanceExceedsZero = true,
    error,
    helperText,
    onUserInput,
    rightToken,
    customComponents,
    onChange,
    setInputValue,
    inputValue,
    ...rest
  } = props

  const handleTokenSelect = useCallback(
    (token: T) => {
      onChangeRightToken?.(token.address)
      setIsTokenListOpen(false)
    },
    [onChangeRightToken]
  )

  const toggleTokenList = useCallback(() => {
    setIsTokenListOpen((prev) => !prev)
  }, [])

  const { formatInput, formatOutput } = useAmountInputFormatter(decimals)
  const [displayValue, setDisplayValue] = useState<string>('')
  const [validationError, setValidationError] = useState<string>('')
  const isInternalChange = useRef(false)
  const isTypingDecimal = useRef(false)

  // Sync displayValue with inputValue only on external changes
  useEffect(() => {
    if (
      !isInternalChange.current &&
      !isTypingDecimal.current &&
      inputValue &&
      !displayValue.endsWith('.') &&
      !displayValue.endsWith(',')
    ) {
      const formatted = formatter?.formatAmount(inputValue) || formatInput(inputValue)
      setDisplayValue(formatted)
    }
    isInternalChange.current = false
  }, [inputValue, formatter, formatInput, displayValue])

  const isMobile = useIsMobileDevice()
  const maxDisabled = !max || max.isZero()
  const hasBalanceValue = max || balance
  const isInvalid = (validateBalanceExceedsZero && max && inputValue && inputValue.gt(max)) || error

  // Log only significant changes
  const prevMax = useRef(max?.toString())
  const prevBalance = useRef(balance?.toString())

  useEffect(() => {
    const currentMax = max?.toString()
    const currentBalance = balance?.toString()

    if (currentMax !== prevMax.current || currentBalance !== prevBalance.current) {
      prevMax.current = currentMax
      prevBalance.current = currentBalance
    }
  }, [max, balance, rightToken?.symbol])

  const formattedBalance = useMemo(() => {
    if (!hasBalanceValue) return '0'
    const balanceValue = max || balance || ZERO
    return formatUnits(balanceValue || '0', decimals)
  }, [hasBalanceValue, max, balance, decimals])

  const inputProps = useMemo(() => {
    const result: any = {}

    if (rightToken) {
      result.endAdornment = (
        <InputAdornment position="end">
          {rightToken ? (
            customComponents?.tokenBadge ? (
              customComponents.tokenBadge({ token: rightToken, onClick: toggleTokenList })
            ) : (
              <Box>Default Token Badge</Box> // This will be replaced in specialized versions
            )
          ) : (
            <Box width={4} />
          )}
        </InputAdornment>
      )
    }
    return result
  }, [rightToken, customComponents, toggleTokenList])

  const [focused, setFocused] = useState(false)

  const handleOnFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const handleOnBlur = useCallback(() => {
    setFocused(false)
  }, [])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (focused) {
        const val = event.target.value
        isInternalChange.current = true
        isTypingDecimal.current = val?.endsWith('.') || val?.endsWith(',') || false

        // Reset states on empty input
        if (!val) {
          setDisplayValue('')
          setInputValue?.(undefined)
          setValidationError('')
          return
        }

        // Always update display value
        setDisplayValue(val)

        // Convert to BigNumber only if input is complete
        if (!val.endsWith('.') && !val.endsWith(',')) {
          const result = formatOutput(val)

          if (result.error) {
            setValidationError(result.error.message)
            return
          }

          setValidationError('')
          setInputValue?.(result.value)
        }
      }
    },
    [focused, formatOutput, setInputValue]
  )

  const handleMaxClick = useCallback(() => {
    if (max && setInputValue) {
      setInputValue(max)
    }
  }, [max, setInputValue])

  return (
    <>
      {customComponents?.tokenList &&
        rightTokenOptions &&
        customComponents.tokenList({
          isOpen: isTokenListOpen,
          onDismiss: toggleTokenList,
          tokens: rightTokenOptions,
          selectedToken: rightToken,
          onSelect: handleTokenSelect,
        })}
      <GreyCard
        gap="16px"
        isInvalid={showBalanceRow ? isInvalid : false}
        style={{
          padding: '1.5rem 1rem',
        }}
      >
        {label && (
          <TYPE.body fontWeight={400} color="dark40">
            {label}
          </TYPE.body>
        )}
        <InputContainer>
          <AmountValueInput
            error={isInvalid}
            InputProps={inputProps}
            inputProps={{
              inputMode: 'decimal',
              autoComplete: 'off',
              autoCorrect: 'off',
              type: 'number',
              placeholder: placeholder || '0.0',
              minLength: 1,
              maxLength: 79,
              spellCheck: 'false',
              max: showBalanceRow ? max : Number.MAX_SAFE_INTEGER,
              min: 0,
            }}
            placeholder={loading ? 'Loading...' : placeholder || '0.0'}
            value={displayValue}
            onChange={handleChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            {...rest}
          />
          <RowBetween marginTop={isMobile ? '1rem' : '0.75rem'}>
            {showBalanceRow && hasBalanceValue ? (
              <RowBetween>
                <Stack>
                  {isInvalid && !helperText && (
                    <BalanceValue color="red">
                      <b>{t('Swap.exceedsBalance')}</b>
                    </BalanceValue>
                  )}
                  {helperText && (
                    <BalanceValue color="red">
                      <b>{helperText}</b>
                    </BalanceValue>
                  )}
                  {props.additionalInfo && <AdditionalInfo>{props.additionalInfo}</AdditionalInfo>}
                </Stack>

                <AmountBalanceRow alignItems="flex-end" flex="1" justify="flex-end">
                  <WalletIcon src={walletIcon || walletSvg} />

                  <BalanceValue>
                    <ScientificNumber value={formattedBalance} />
                  </BalanceValue>
                  {max && !max.isZero() && <MaxButton onClick={handleMaxClick}>{t('Swap.max')}</MaxButton>}
                </AmountBalanceRow>
              </RowBetween>
            ) : null}
          </RowBetween>
        </InputContainer>
      </GreyCard>
    </>
  )
}
