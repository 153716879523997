import { CommonLayout, FlexContainer } from 'components'
import { CardCentered } from 'components/Card'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import { useYandexMetrikaHit } from 'hooks/useYandexMetrika'
import { useActiveWeb3React } from 'hooks/web3'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Claim } from './Claim'
import StakeBlock from './StakeBlock'

const PageWrapper = styled.div`
  flex: 1;
  justify-content: center;
  margin: auto;
  width: 100%;

  display: flex;
`

const Card = styled(CardCentered)`
  width: 100%;
  gap: 1.5rem;
  padding: 2rem 1.5rem;
  margin: 0;

  ${({ theme }) =>
    theme.mediaWidth.upToTablet`
   padding: 1.5rem 1rem;
  `}
`

const Title = styled.h2`
  font-size: 1.875rem;
  font-family: 'Helvetica Neue', sans-serif;

  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 2.25rem */
  letter-spacing: -0.01875rem;
  margin: 0;

  ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 1.5rem;
    letter-spacing: -0.015rem;
  `}
`

const Main = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 34.75rem 1fr;
  gap: 1.25rem;
  position: relative;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 6.5rem;
  ${({ theme }) =>
    theme.mediaWidth.upToTablet`
    width: 100%;
    transition: all ease-in-out 300ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4.5rem;
  `}
`

export default function Farm() {
  useYandexMetrikaHit()

  const { account } = useActiveWeb3React()

  const [type, setType] = useState<'stake' | 'unstake'>('stake')

  const { t } = useTranslation()

  const mainRef = useRef<HTMLDivElement | null>(null)

  const isMobile = useIsMobileDevice()
  useEffect(() => {
    if (!isMobile && mainRef.current) {
      mainRef.current.style.transform = 'translateX(0)'
    }
  }, [isMobile])

  return (
    <CommonLayout>
      <PageWrapper>
        <Main ref={mainRef}>
          <div />
          <Card>
            <FlexContainer>
              <Title>{type === 'stake' ? t('Farm.stakeTitle') : t('Farm.unstakeTitle')}</Title>
            </FlexContainer>
            <StakeBlock onTypeChange={setType} />
          </Card>
          {account && <Claim />}
        </Main>
      </PageWrapper>
    </CommonLayout>
  )
}
