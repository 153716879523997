import { WXFI_MAINNET } from 'constants/app-contracts'
import { ZERO_ADDRESS } from 'constants/misc'
import { useERC20Symbol } from 'hooks/useContractName'
import { useCallback, useMemo, useState } from 'react'

import { TokenPairState, TokenSelectionState } from '../../types/tokens'
import { useAvailableTokens } from './useAvailableTokens'
import { filterOutToken, findTokenByAddressOrSymbol } from './utils'

const DEBUG_TAG = 'USE_TOKEN_SELECTION'

/**
 * Hook for managing token selection
 */
export const useTokenSelection = () => {
  const { tokens, pools, loading: loadingTokens, error: tokensError } = useAvailableTokens()

  // Token selection state
  const [tokenInAddress, setTokenInAddress] = useState<string>(ZERO_ADDRESS)
  const [tokenOutAddress, setTokenOutAddress] = useState<string>(WXFI_MAINNET)

  // Get symbols for tokens
  const { symbol: symbolFirst, loading: loadingSymbolFirst } = useERC20Symbol(tokenInAddress)
  const { symbol: symbolSecond, loading: loadingSymbolSecond } = useERC20Symbol(tokenOutAddress)

  // Find token models
  const tokenInModel = useMemo(() => {
    return findTokenByAddressOrSymbol(tokens, tokenInAddress)
  }, [tokens, tokenInAddress])

  const tokenOutModel = useMemo(() => {
    return findTokenByAddressOrSymbol(tokens, tokenOutAddress)
  }, [tokens, tokenOutAddress])

  // Get available tokens for each side
  const availableTokensIn = useMemo(() => {
    return filterOutToken(tokens, tokenOutAddress)
  }, [tokens, tokenOutAddress])

  const availableTokensOut = useMemo(() => {
    return filterOutToken(tokens, tokenInAddress)
  }, [tokens, tokenInAddress])

  // Handle token selection
  const handleTokenInChange = useCallback((addressOrSymbol: string) => {
    try {
      setTokenInAddress(addressOrSymbol)
    } catch (error) {
      console.error(`[${DEBUG_TAG}:handleTokenInChange] Error:`, {
        error,
        addressOrSymbol,
      })
    }
  }, [])

  const handleTokenOutChange = useCallback((addressOrSymbol: string) => {
    try {
      setTokenOutAddress(addressOrSymbol)
    } catch (error) {
      console.error(`[${DEBUG_TAG}:handleTokenOutChange] Error:`, {
        error,
        addressOrSymbol,
      })
    }
  }, [])

  // Handle token swap
  const handleSwapTokens = useCallback(() => {
    try {
      const tempIn = tokenInAddress
      setTokenInAddress(tokenOutAddress)
      setTokenOutAddress(tempIn)
    } catch (error) {
      console.error(`[${DEBUG_TAG}:handleSwapTokens] Error:`, error)
    }
  }, [tokenInAddress, tokenOutAddress])

  // Compute token states
  const tokenInState = useMemo(
    (): TokenSelectionState => ({
      selectedToken: tokenInAddress,
      loading: loadingTokens || loadingSymbolFirst,
      tokenModel: tokenInModel,
      availableTokens: availableTokensIn,
      error: tokensError,
    }),
    [tokenInAddress, loadingTokens, loadingSymbolFirst, tokenInModel, availableTokensIn, tokensError]
  )

  const tokenOutState = useMemo(
    (): TokenSelectionState => ({
      selectedToken: tokenOutAddress,
      loading: loadingTokens || loadingSymbolSecond,
      tokenModel: tokenOutModel,
      availableTokens: availableTokensOut,
      error: tokensError,
    }),
    [tokenOutAddress, loadingTokens, loadingSymbolSecond, tokenOutModel, availableTokensOut, tokensError]
  )

  // Compute pair state
  const pairState = useMemo((): TokenPairState => {
    const canSwap = !loadingTokens && !!tokenInAddress && !!tokenOutAddress && tokenInAddress !== tokenOutAddress

    return {
      tokenIn: tokenInState,
      tokenOut: tokenOutState,
      canSwapTokens: canSwap,
      loading: loadingTokens,
      error: tokensError,
    }
  }, [tokenInState, tokenOutState, loadingTokens, tokenInAddress, tokenOutAddress, tokensError])

  return {
    ...pairState,
    pools,
    handleTokenInChange,
    handleTokenOutChange,
    handleSwapTokens,
    symbolFirst,
    symbolSecond,
  }
}
