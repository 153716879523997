import { Contract } from '@ethersproject/contracts'
import { ListenerOptions } from '@uniswap/redux-multicall'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import useBlockNumber, { useMainnetBlockNumber } from 'hooks/useBlockNumber'
import { useEffect, useState } from 'react'
import { SkipFirst } from 'types/tuple'

import multicall from './updater'

export { NEVER_RELOAD } from '@uniswap/redux-multicall' // re-export for convenience

// Create wrappers for hooks so consumers don't need to get latest block themselves

type SkipFirstTwoParams<T extends (...args: any) => any> = SkipFirst<Parameters<T>, 2>

export function useMultipleContractSingleData(
  ...args: SkipFirstTwoParams<typeof multicall.hooks.useMultipleContractSingleData>
) {
  const { chainId, latestBlock } = useCallContext()
  return multicall.hooks.useMultipleContractSingleData(chainId, latestBlock, ...args)
}

interface CallState {
  readonly valid: boolean
  readonly result?: any
  readonly loading: boolean
  readonly syncing: boolean
  readonly error: boolean
}

const INVALID_CALL_STATE: CallState = { valid: false, result: undefined, loading: false, syncing: false, error: false }
const LOADING_CALL_STATE: CallState = { valid: true, result: undefined, loading: true, syncing: true, error: false }

function isValidContract(contract: Contract | null | undefined): contract is Contract {
  return contract !== null && contract !== undefined
}

async function callContract(contract: Contract, methodName: string, inputs: any[] = []): Promise<any> {
  if (!(methodName in contract)) {
    throw new Error(`Method ${methodName} not found on contract`)
  }
  return contract[methodName](...inputs)
}

export function useSingleCallResult(
  contract: Contract | null | undefined,
  methodName: string,
  inputs: unknown[] = [],
  options?: ListenerOptions
): CallState {
  const { chainId } = useCallContext()
  const [result, setResult] = useState<CallState>(LOADING_CALL_STATE)
  const inputsString = JSON.stringify(inputs)

  useEffect(() => {
    let mounted = true

    if (!contract || !methodName || !chainId) {
      setResult(INVALID_CALL_STATE)
      return
    }

    async function fetchData() {
      if (!contract || !mounted) return

      try {
        const response = await callContract(contract, methodName, inputs)
        if (mounted) {
          setResult({
            valid: true,
            result: [response],
            loading: false,
            syncing: false,
            error: false,
          })
        }
      } catch (error) {
        if (mounted) {
          console.error(`Error calling ${methodName}:`, error)
          setResult({
            valid: true,
            result: undefined,
            loading: false,
            syncing: false,
            error: true,
          })
        }
      }
    }

    fetchData()

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract, methodName, chainId, inputsString])

  return result
}

export function useMainnetSingleCallResult(...args: SkipFirstTwoParams<typeof multicall.hooks.useSingleCallResult>) {
  const latestMainnetBlock = useMainnetBlockNumber()
  return multicall.hooks.useSingleCallResult(ChainId.MAINNET, latestMainnetBlock, ...args)
}

export function useSingleContractMultipleData(
  ...args: SkipFirstTwoParams<typeof multicall.hooks.useSingleContractMultipleData>
) {
  const { chainId, latestBlock } = useCallContext()
  return multicall.hooks.useSingleContractMultipleData(chainId, latestBlock, ...args)
}

function useCallContext() {
  const { chainId } = useWeb3React()
  const latestBlock = useBlockNumber()
  return { chainId, latestBlock }
}
