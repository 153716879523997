import { Currency } from '@uniswap/sdk-core'
import { Dots } from 'components/Dots'
import { FormActionBtn } from 'components/FormActionBtn/FormActionBtn'
import ImgEllipse from 'components/icons/ellipse'
import { RowBetween } from 'components/Row'
import { useSwapRouter, useWXfiContract } from 'constants/app-contracts'
import { BigNumber } from 'ethers'
import { useCurrency } from 'hooks/Tokens'
import JSBI from 'jsbi'
import { useDebugMode } from 'lib/hooks/useDebugMode'
import { useResetApproval } from 'lib/hooks/useResetApproval'
import { useTokenAllowanceListener } from 'lib/hooks/useTokenAllowanceListener'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { css } from 'styled-components'
import { rotate, TYPE } from 'theme/theme'
import { BN_1E18 } from 'utils/isZero'

import { BtnApprovTx } from '../../components/Button'
import { ApprovalState, useSimpleApproveCallback } from '../../hooks/useApproveCallback'

const ApproveBtn = styled(BtnApprovTx)`
  ${({ theme }) => theme.mediaWidth.upToPhone`
    width: 100%;
  `};
`

const EllipseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.darkOrange};

  ${css`
    animation: 2s ${rotate} linear infinite;
  `}
`

interface ICheckerCommon {
  currency: Currency
  children?: any
}

interface IChecker extends ICheckerCommon {
  address?: string
  disabled?: boolean
  border?: BigNumber
  asBtn?: React.ElementType
}

const LOW_BORDER = BN_1E18

export const ApproveProvider = ({ children }: { children: any }) => {
  const [approvedState, setApprovedState] = useState({
    approved: {},
    pending: {},
  })

  const setApproved = useCallback(
    (key: string, value: boolean) => {
      setApprovedState((prev) => {
        return {
          ...prev,
          approved: {
            ...prev.approved,
            [key]: value,
          },
        }
      })
    },
    [setApprovedState]
  )

  const setPending = useCallback(
    (key: string, value: boolean) => {
      setApprovedState((prev) => {
        return {
          ...prev,
          pending: {
            ...prev.pending,
            [key]: value,
          },
        }
      })
    },
    [setApprovedState]
  )

  const memoizedApprove = useMemo(() => {
    return {
      ...approvedState,
      setApproved,
      setPending,
    }
  }, [approvedState, setApproved, setPending])

  return <ApproveContext.Provider value={memoizedApprove}>{children}</ApproveContext.Provider>
}

export const ConfirmInWalletBlock = ({ children, calledWallet }: { children: any; calledWallet: boolean }) => {
  const { t } = useTranslation()

  if (calledWallet) {
    return (
      <ApproveBtn disabled>
        <RowBetween align="center" flex="1">
          <TYPE.mediumHeader color="darkOrange35">
            <Dots>{t('confirmInWallet')}</Dots>
          </TYPE.mediumHeader>
          <EllipseContainer>
            <ImgEllipse />
          </EllipseContainer>
        </RowBetween>
      </ApproveBtn>
    )
  }

  return children
}

interface IApprovesState {
  approved: { [key: string]: boolean }
  pending: { [key: string]: boolean }
  setApproved: (key: string, value: boolean) => void
  setPending: (key: string, value: boolean) => void
}

export const ApproveContext = createContext<IApprovesState>({
  approved: {},
  pending: {},
  setApproved: () => console.log('setApproved'),
  setPending: () => console.log('setPending'),
})

export const ApproveCheckerERC20 = ({
  currency,
  children,
  address,
  disabled = false,
  border = LOW_BORDER,
  asBtn: AsBtn = ApproveBtn,
}: IChecker) => {
  const { t } = useTranslation()
  const isDebugMode = useDebugMode()
  const { allowance } = useTokenAllowanceListener(currency, address)
  const {
    approve: approveACallback,
    txInfo,
    calledWallet,
    approvalState,
  } = useSimpleApproveCallback(currency, border, address)

  const isApprovePending = approvalState === ApprovalState.PENDING

  const { resetApproval, hasAllowance, isPending: isResetPending } = useResetApproval(currency, address)

  // we need an existence check on parsed amounts for single-asset deposits
  const needApprove = useMemo(() => {
    if (!currency || !allowance) return false
    return JSBI.lessThan(allowance, JSBI.BigInt(border.toString()))
  }, [currency, allowance, border])

  const { setApproved, setPending } = useContext(ApproveContext)

  useEffect(() => {
    if (currency?.wrapped.address) {
      setApproved(currency.wrapped.address, !needApprove)
    }
  }, [needApprove, currency?.wrapped.address, setApproved])

  useEffect(() => {
    if (currency?.wrapped.address) {
      setPending(currency.wrapped.address, isApprovePending)
    }
  }, [isApprovePending, currency?.wrapped.address, setPending])

  return (
    <ConfirmInWalletBlock calledWallet={calledWallet}>
      {needApprove ? (
        <AsBtn onClick={approveACallback} disabled={isApprovePending || disabled}>
          <FormActionBtn
            pending={isApprovePending}
            labelActive={t('Swap.approve')}
            labelInProgress={t('Swap.approving')}
          />
        </AsBtn>
      ) : (
        <>
          {hasAllowance && isDebugMode && (
            <AsBtn onClick={resetApproval} disabled={disabled} style={{ marginBottom: '10px' }}>
              <FormActionBtn
                pending={isResetPending}
                labelActive={t('Swap.resetApproval')}
                labelInProgress={t('Swap.resettingApproval')}
              />
            </AsBtn>
          )}
          {children}
        </>
      )}
    </ConfirmInWalletBlock>
  )
}

interface IApproveProps {
  currency?: Currency
  token?: string
  children?: any
  border?: BigNumber
  asBtn?: React.ElementType
}

export const ApproveCheckerSwap = ({ token, children, border }: IApproveProps) => {
  const contract = useSwapRouter()
  const currency = useCurrency(token)

  if (!currency) {
    return null
  }

  return (
    <ApproveCheckerERC20 currency={currency} address={contract?.address} border={border}>
      {children}
    </ApproveCheckerERC20>
  )
}

export const ApproveCheckerWXfi = ({ token, children, border }: IApproveProps) => {
  const contract = useWXfiContract()
  const currency = useCurrency(token)

  if (!currency) {
    return null
  }

  return (
    <ApproveCheckerERC20 currency={currency} address={contract?.address} border={border}>
      {children}
    </ApproveCheckerERC20>
  )
}
