import { Currency } from '@uniswap/sdk-core'
import { useTokenContract } from 'hooks/useContract'
import { useActiveWeb3React } from 'hooks/web3'
import JSBI from 'jsbi'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useAllTransactions } from 'state/transactions/hooks'

const POLLING_INTERVAL = 1000

// Hook to monitor token allowance changes
export function useTokenAllowanceListener(currency: Currency | undefined | null, spender?: string) {
  const { account } = useActiveWeb3React()
  const token = currency?.isToken ? currency : undefined
  const [allowance, setAllowance] = useState<JSBI | undefined>()
  const pollingInterval = useRef<NodeJS.Timeout>()

  const tokenContract = useTokenContract(token?.address)
  const allTransactions = useAllTransactions()

  const updateAllowance = useCallback(async () => {
    if (!tokenContract || !account || !spender) return
    try {
      const allowanceValue = await tokenContract.allowance(account, spender)
      setAllowance(JSBI.BigInt(allowanceValue.toString()))
    } catch (error) {
      console.error('Failed to fetch allowance:', error)
    }
  }, [tokenContract, account, spender])

  // Monitor all transactions for approvals
  useEffect(() => {
    if (!token?.address || !spender) return

    // Check if any approval transaction was recently confirmed
    const hasRecentApproval = Object.values(allTransactions).some((tx) => {
      if (!tx.receipt) return false
      const approval = tx.approval
      return (
        approval &&
        approval.spender === spender &&
        approval.tokenAddress.toLowerCase() === token.address.toLowerCase() &&
        tx.receipt.status === 1 // transaction was successful
      )
    })

    if (hasRecentApproval) {
      // Force several updates after approval to ensure we get the latest state
      const checkAllowanceUpdated = async () => {
        for (let i = 0; i < 3; i++) {
          await updateAllowance()
          await new Promise((resolve) => setTimeout(resolve, 1000))
        }
      }
      checkAllowanceUpdated()
    }
  }, [allTransactions, token?.address, spender, updateAllowance])

  // Start polling
  useEffect(() => {
    if (!tokenContract || !account || !spender) return

    // Initial fetch
    updateAllowance()

    // Setup polling
    pollingInterval.current = setInterval(updateAllowance, POLLING_INTERVAL)

    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current)
      }
    }
  }, [tokenContract, account, spender, updateAllowance])

  return {
    allowance,
    updateAllowance,
  }
}
