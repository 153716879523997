import { BigNumber } from 'ethers'
import { ZERO } from 'utils/isZero'

const DEBUG_TAG = 'POINTS_UTILS'

/**
 * Calculate bonus points based on amount and percentage
 * @param amount - Amount to calculate points from
 * @param percentage - Percentage of points (e.g. 10 for 10%)
 */
export const calculateBonusPoints = (amount: BigNumber | undefined, percentage = 10): BigNumber => {
  if (!amount) return ZERO

  try {
    return amount.mul(percentage).div(100)
  } catch (error) {
    console.error(`[${DEBUG_TAG}:calculateBonusPoints] Error:`, {
      error,
      amount: amount.toString(),
      percentage,
    })
    return ZERO
  }
}

/**
 * Check if path includes xUSD token
 */
export const hasXUsdInPath = (path: string[] | undefined, xUsdAddress: string): boolean => {
  if (!path || !xUsdAddress) return false
  return path.some((p) => p.toLowerCase() === xUsdAddress.toLowerCase())
}
