import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// Hook to check if debug mode is enabled via URL params
export function useDebugMode() {
  const { search } = useLocation()

  return useMemo(() => {
    const params = new URLSearchParams(search)
    return params.get('debug') === 'true'
  }, [search])
}
