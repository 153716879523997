import { useEffect, useState } from 'react'

import { useIsTransactionPending } from '../../state/transactions/hooks'

export const useDelayedTransactionStatus = (hash: string | undefined) => {
  const [delayedIsPending, setDelayedIsPending] = useState(true)
  const isPending = useIsTransactionPending(hash)

  useEffect(() => {
    if (!hash) {
      setDelayedIsPending(false)
      return
    }

    const timer = setTimeout(() => {
      setDelayedIsPending(isPending)
    }, 4000)

    return () => clearTimeout(timer)
  }, [hash, isPending])

  return delayedIsPending
}
