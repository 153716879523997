import { Button, TransactionLoader } from 'components'
import { ColumnCenter } from 'components/Column'
import { useDelayedTransactionStatus } from 'lib/hooks/useDelayedTransactionStatus'
import { FC, PropsWithChildren, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ColumnCenterStyled = styled(ColumnCenter)`
  justify-content: center;
  gap: 16px;
`

type Props = PropsWithChildren<{
  isLoading?: boolean
  hash: string
  onBack?: () => void
  onStatusChange?: (status: 'pending' | 'done') => void
  showAddToken?: boolean
  tokenAddress?: string
  tokenSymbol?: string
  tokenDecimals?: number
  tokenImageUrl?: string
}>

export const TxStatusView: FC<Props> = ({
  isLoading = false,
  hash,
  children,
  onBack,
  onStatusChange,
  showAddToken = false,
  tokenAddress,
  tokenSymbol,
  tokenDecimals,
  tokenImageUrl,
}) => {
  const { t } = useTranslation()

  const isPending = useDelayedTransactionStatus(hash)
  const isInProcess = isLoading || isPending

  useEffect(() => {
    if (isPending) {
      onStatusChange?.('pending')
    } else {
      onStatusChange?.('done')
    }
  }, [isPending, onStatusChange])

  const handleAddTokenToWallet = useCallback(async () => {
    if (!window.ethereum?.isMetaMask) {
      console.error('Non-MetaMask wallet detected')
      return
    }

    try {
      const success = await (window.ethereum as any).request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImageUrl,
          },
        },
      })

      if (success) {
        console.log('Token successfully added to wallet')
      } else {
        console.error('User rejected token addition')
      }
    } catch (error) {
      console.error('Error adding token:', error)
    }
  }, [tokenAddress, tokenSymbol, tokenDecimals, tokenImageUrl])

  return (
    <ColumnCenterStyled>
      <TransactionLoader done={!isInProcess} />

      {children}

      {!isInProcess && onBack && (
        <>
          {showAddToken && (
            <Button onClick={handleAddTokenToWallet} size="medium" style={{ width: '100%' }}>
              Add {tokenSymbol} to the wallet
            </Button>
          )}
          <Button variant="outlined" onClick={onBack} size="medium" style={{ width: '100%' }}>
            {t('transactionStatus.close')}
          </Button>
        </>
      )}
    </ColumnCenterStyled>
  )
}
