import { Stack } from '@mui/material'
import { FlexContainer } from 'components'
import { TokenIcon } from 'components/blocks/AmountInput/styles'
import { ScientificNumber } from 'components/blocks/ScientificNumber'
import { TxStatusView } from 'components/TxStatusView/TxStatusView'
import { ZERO_ADDRESS } from 'constants/misc'
import { useTokenAsset } from 'hooks/useTokenAsset'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const InfoBox = styled.div`
  border-radius: 0.75rem;
  border: 1px solid var(--Div-Stroke, #d8ecff);
  display: flex;
  padding: 1.25rem 1rem;
  align-items: center;
  width: 100%;
  gap: 0.75rem;
`

interface IPendingClaimViewProps {
  hash: string
  amountIn: string
  onBack: () => void
}

const StyledTokenIcon = styled(TokenIcon)`
  width: 1.125rem;
  height: 1.125rem;
`

export const PendingClaimView = ({ hash, amountIn, onBack }: IPendingClaimViewProps) => {
  const xfiToken = useTokenAsset(ZERO_ADDRESS)

  const [status, setStatus] = useState<'pending' | 'done'>('pending')

  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    onBack()
  }, [onBack])

  return (
    <Stack spacing={2}>
      <TxStatusView hash={hash} onBack={handleClose} onStatusChange={setStatus}>
        <InfoBox>
          <span>{status === 'pending' ? t('Farm.pendingClaim') : t('Farm.claimed')}</span>
          <FlexContainer
            additionalStyles={{
              gap: '0.5rem',
              alignItems: 'center',
              justifyContent: 'flex-start',
              display: 'inline-flex',
              flex: 0,
            }}
          >
            <StyledTokenIcon
              src={xfiToken?.icon}
              alt={xfiToken?.symbol}
              onError={(e) => {
                e.currentTarget.src = '/images/tokens/unknown.png'
              }}
            />
            <FlexContainer additionalStyles={{ flex: 1, gap: '0.25rem' }}>
              <span>
                <ScientificNumber value={amountIn} />
              </span>
              <span> {xfiToken?.symbol}</span>
            </FlexContainer>
          </FlexContainer>
        </InfoBox>
      </TxStatusView>
    </Stack>
  )
}
