import { useQuery } from '@tanstack/react-query'
import { API_URL } from 'api/api'
import { TokenSymbol } from 'components/blocks/AmountInput/TokenSymbol'
import { useMemo } from 'react'
import { Address } from 'viem'

import { SwapInfoResponse, SwapInfoResult } from '../types/api.types'

const DEBUG_TAG = 'USE_SWAP_INFO'

const NATIVE_TOKEN_ADDRESS = '0x0000000000000000000000000000000000000000'

const getNativeOrAddress = (token?: Address | string): Address | undefined => {
  if (!token) return undefined
  return token?.toLowerCase() === TokenSymbol.xfi.toLowerCase() ? (NATIVE_TOKEN_ADDRESS as Address) : (token as Address)
}

const fetchSwapInfo = async (url: string): Promise<SwapInfoResponse> => {
  if (!url) {
    throw new Error('Invalid parameters provided')
  }

  console.log(`[${DEBUG_TAG}] Fetching swap info from:`, url)
  const response = await fetch(url)
  const data = await response.json()

  console.log(`[${DEBUG_TAG}] API Response:`, {
    data: data.data ? `${data.data.slice(0, 10)}...` : undefined,
    transferAddress: data.transferAddress,
    transferValue: data.transferValue,
    approvalAddress: data.approvalAddress,
    estimateAmount: data.estimateAmount,
    hopsCount: data.hopsCount,
    fee: data.fee,
  })

  if ('detail' in data) {
    const error = Array.isArray(data.detail) ? data.detail[0].msg : data.detail
    console.error(`[${DEBUG_TAG}] API Error:`, error)
    throw new Error(error)
  }

  return data
}

interface UseSwapInfoParams {
  to: Address | string
  token0?: Address | string
  token1?: Address | string
  amountIn?: string
  functionType?: string
  slippage?: number
  deadline?: number
}

/**
 * Hook for fetching swap execution information from API using react-query
 */
export const useSwapInfo = ({
  to,
  token0,
  token1,
  amountIn = '1',
  functionType,
  slippage = 0.05,
  deadline,
}: UseSwapInfoParams): {
  result?: SwapInfoResult
  pending: boolean
} => {
  const url = useMemo(() => {
    const actualToken0 = getNativeOrAddress(token0)
    const actualToken1 = getNativeOrAddress(token1)

    if (!actualToken0 || !actualToken1) {
      return ''
    }

    const params = new URLSearchParams({
      to,
      token0: actualToken0,
      token1: actualToken1,
      amount_in: amountIn?.toString(),
      slippage: slippage.toString(),
      ...(functionType && { function_type: functionType }),
      ...(deadline && { deadline: deadline.toString() }),
    })

    return `${API_URL}swap/info?${params.toString()}`
  }, [token0, token1, amountIn, functionType, slippage, deadline, to])

  const { data, isLoading, error } = useQuery({
    queryKey: ['swapInfo', token0, token1, amountIn, functionType, slippage, deadline, to],
    queryFn: () => fetchSwapInfo(url),
    enabled: !!url && +amountIn > 0,
    staleTime: 30000, // Consider data stale after 30 seconds
    gcTime: 60000, // Keep data in cache for 1 minute
  })

  const result = useMemo((): SwapInfoResult | undefined => {
    if (error) {
      return {
        error: error instanceof Error ? error.message : 'Unknown error',
      }
    }

    if (!data) return undefined

    return {
      data: data.data,
      transferAddress: data.transferAddress,
      transferValue: data.transferValue,
      approvalAddress: data.approvalAddress,
      estimateTransferSeconds: data.estimateTransferSeconds,
      estimateAmount: data.estimateAmount,
      hopsCount: data.hopsCount,
      fee: data.fee,
    }
  }, [data, error])

  return {
    result,
    pending: isLoading,
  }
}
