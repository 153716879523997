import { WarningBlock } from 'components/WarningBlock/WarningBlock'
import { BigNumber } from 'ethers'
import { useTranslation } from 'react-i18next'

import { MAX_PRICE_IMPACT } from '../../AdditionalInfo'

interface SwapWarningsProps {
  pathError?: string
  amountIn?: BigNumber
  priceChange: number
}

export const SwapWarnings = ({ pathError, amountIn, priceChange }: SwapWarningsProps) => {
  const { t } = useTranslation()

  console.log('priceChange', priceChange.toString())

  return (
    <>
      {pathError && amountIn && !amountIn.isZero() && <WarningBlock text={pathError} />}

      {priceChange >= MAX_PRICE_IMPACT && (
        <WarningBlock
          text={`${t('Swap.priceImpactWarn1')} (${priceChange?.toFixed(2)}%).  ${t('Swap.priceImpactWarn2')}`}
        />
      )}
    </>
  )
}
