import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { useMemo } from 'react'
import { parseToWei } from 'utils/formatWei'

import { TokenAmountFormatter } from '../../types/formatting'

const DEBUG_TAG = 'TOKEN_AMOUNT_FORMATTER'

/**
 * Convert scientific notation to decimal string
 */
const fromScientific = (num: number | string): string => {
  const str = num.toString()
  if (!/\d+\.?\d*e[+-]*\d+/i.test(str)) {
    return str
  }

  const [base, exponent] = str.toLowerCase().split('e')
  const exp = parseInt(exponent)
  const baseNum = parseFloat(base)

  // Convert to regular number string
  return baseNum.toLocaleString('fullwide', {
    useGrouping: false,
    maximumFractionDigits: 20,
  })
}

/**
 * Hook for formatting token amounts
 * @param decimals - Token decimals
 * @returns TokenAmountFormatter interface
 */
export const useTokenAmountFormatter = (decimals?: number): TokenAmountFormatter => {
  return useMemo(() => {
    return {
      formatAmount: (amountInWei?: BigNumber | null): string => {
        if (!amountInWei || decimals === undefined) return '0'
        try {
          const formatted = formatUnits(amountInWei, decimals)
          // Remove trailing zeros and decimal point for whole numbers
          return formatted.replace(/\.?0+$/, '')
        } catch (error) {
          console.error(`[${DEBUG_TAG}:formatAmount] Error:`, {
            error,
            amount: amountInWei.toString(),
            decimals,
          })
          return '0'
        }
      },

      formatRawAmount: (amount?: string | number): string => {
        if (!amount || decimals === undefined) return '0'
        try {
          const decimalStr = fromScientific(amount)
          return formatUnits(parseToWei(decimalStr, decimals), decimals)
        } catch (error) {
          console.error(`[${DEBUG_TAG}:formatRawAmount] Error:`, {
            error,
            amount,
            decimals,
          })
          return '0'
        }
      },
    }
  }, [decimals])
}
