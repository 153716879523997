import { useQuery } from '@tanstack/react-query'
import { API_URL } from 'api/api'
import { TokenSymbol } from 'components/blocks/AmountInput/TokenSymbol'
import { useMemo } from 'react'

import { Token, TokensState } from '../../types/tokens'

const DEBUG_TAG = 'USE_AVAILABLE_TOKENS'

// Native XFI token model
const XFI_MODEL: Token = {
  address: '0x0000000000000000000000000000000000000000', // Zero address for native token
  name: 'XFI',
  symbol: TokenSymbol.xfi,
  decimals: 18,
  is_blacklisted: false,
}

type TokensResponse = [Token]

const fetchTokens = async (url: string): Promise<TokensResponse> => {
  const response = await fetch(url)
  const data = await response.json()
  return data
}

/**
 * Hook for fetching available tokens
 */
export const useAvailableTokens = (): TokensState => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['availableTokens'],
    queryFn: () => fetchTokens(`${API_URL}overview/tokens`),
    staleTime: 30000, // Consider data stale after 30 seconds
    gcTime: 60000, // Keep data in cache for 1 minute
  })

  return useMemo(() => {
    try {
      const defaultState: TokensState = {
        tokens: [XFI_MODEL],
        pools: [],
        loading: isLoading,
        error: undefined,
      }

      if (!data || !Array.isArray(data)) {
        return defaultState
      }

      // Now data is Token array directly
      const tokens = data as Token[]
      const availableTokens = tokens.filter((token) => !token.is_blacklisted)
      availableTokens.unshift(XFI_MODEL)

      return {
        tokens: availableTokens,
        pools: [],
        loading: isLoading,
        error: undefined,
      }
    } catch (err) {
      console.error(`[${DEBUG_TAG}] Error processing tokens:`, err)
      return {
        tokens: [XFI_MODEL],
        pools: [],
        loading: false,
        error: err instanceof Error ? err.message : 'Unknown error',
      }
    }
  }, [data, isLoading, error])
}
