import { useTransition } from 'hooks/useApiCall'
import { useEffect } from 'react'
import useSWRWrapper from 'swr'

import { useLocalStorageCache } from './useLocalStorageCache'

export const useCachedSWR = <T>(key: string, fetcher: (key: string) => Promise<T>, ttl?: number) => {
  const { data: freshData, error, isLoading } = useSWRWrapper<T>(key, fetcher)
  const { cachedData, setCacheData } = useLocalStorageCache<T>(key, ttl)

  const data = useTransition<T>(freshData!)

  useEffect(() => {
    if (data) {
      setCacheData(data)
    }
  }, [data, setCacheData])

  return {
    data: data || cachedData,
    error,
    isLoading: !cachedData && isLoading,
    isFreshData: !!data,
  }
}
