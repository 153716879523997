import farmABI from 'abis/farm/farm.json'
import { ILPToken } from 'components/blocks/AmountInput/lp/types'
import { Contract } from 'ethers'
import { useActiveWeb3React } from 'hooks/web3'
import { useCallback, useEffect, useState } from 'react'
import { Address } from 'viem'

import { useFarmLPTokens } from './useFarmLPTokens'

interface FarmReward extends Omit<ILPToken, 'farmAddress'> {
  farmAddress: Address
  reward: bigint
}

export function useUserFarmsRewards() {
  const { account, provider } = useActiveWeb3React()
  const { lpTokens, isLoading: isLpTokensLoading } = useFarmLPTokens()
  const [rewards, setRewards] = useState<FarmReward[] | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const fetchRewards = useCallback(async () => {
    setIsLoading(true)

    if (!account || !provider || !lpTokens || lpTokens.length === 0) {
      setRewards(null)
      setIsLoading(false)
      return
    }

    try {
      const rewardsPromises = lpTokens.map(async (lpToken) => {
        const contract = new Contract(lpToken.farmAddress as string, farmABI, provider)
        const reward = await contract.earned(account)

        return {
          ...lpToken,
          reward: BigInt(reward.toString()),
        }
      })

      const farmRewards = (await Promise.all(rewardsPromises)).filter(
        (reward): reward is FarmReward => reward !== null && reward.reward > 0n
      )
      setRewards(farmRewards.length > 0 ? farmRewards : [])
    } catch (error) {
      console.error('Error fetching farm rewards:', error)
      setRewards(null)
    } finally {
      setIsLoading(false)
    }
  }, [account, provider, lpTokens])

  useEffect(() => {
    fetchRewards()
  }, [fetchRewards])

  return {
    rewards,
    isLoading: isLpTokensLoading || isLoading,
    refetch: fetchRewards,
  }
}
