import { useActiveWeb3React } from 'hooks/web3'
import { useCallback, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { Address } from 'viem'

export enum ContractTxState {
  UNKNOWN = 'UNKNOWN',
  LOADING = 'LOADING',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface ContractTxParams {
  callData: `0x${string}`
  transferValue?: string | number
  contractAddress: Address
  description?: string
}

interface ContractTxResult {
  execute: (params: ContractTxParams) => Promise<void>
  state: ContractTxState
  error: Error | null
  hash?: string
  reset: () => void
}

export const useContractTransaction = (): ContractTxResult => {
  const { provider, account } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const [error, setError] = useState<Error | null>(null)
  const [state, setState] = useState<ContractTxState>(ContractTxState.UNKNOWN)
  const [hash, setHash] = useState<string>()

  const execute = useCallback(
    async ({ callData, transferValue, contractAddress, description = 'Contract interaction' }: ContractTxParams) => {
      if (!provider || !account) {
        throw new Error('Missing dependencies')
      }

      try {
        const network = await provider.getNetwork()
        const blockNumber = await provider.getBlockNumber()
        console.log('🌐 [ContractTx] Provider connection details:', {
          chainId: network.chainId,
          name: network.name,
          ensAddress: network.ensAddress,
          currentBlock: blockNumber,
          connection: {
            url: provider.connection?.url || 'Unknown',
          },
        })

        console.log('🔄 [ContractTx] Starting transaction execution...')
        console.log('👤 [ContractTx] Account:', account)
        console.log('💰 [ContractTx] Transfer value:', transferValue ? Number(transferValue).toString() : '0', 'ETH')

        setState(ContractTxState.LOADING)
        setError(null)

        // Get signer
        const signer = provider.getSigner(account)

        // Prepare transaction
        const tx = {
          to: contractAddress,
          data: callData,
          value: transferValue ? transferValue.toString() : '0',
        }

        console.log('📝 [ContractTx] Transaction data:', {
          to: tx.to,
          value: tx.value,
          dataLength: tx.data.length,
          callData: callData.slice(0, 66) + '...', // Show only beginning of callData
        })

        // Send transaction
        console.log('📤 [ContractTx] Sending transaction to local node...')
        const response = await signer.sendTransaction(tx)

        // Add transaction to the store
        addTransaction(response, {
          summary: description,
          type: 'contract',
        })

        setHash(response.hash)
        setState(ContractTxState.PENDING)
        console.log('📨 [ContractTx] Transaction sent! Hash:', response.hash)

        // Wait for transaction confirmation
        const receipt = await response.wait()
        setState(receipt.status === 1 ? ContractTxState.SUCCESS : ContractTxState.ERROR)
      } catch (err) {
        console.error('❌ [ContractTx] Failed to execute transaction:', err)

        // Check if error is user rejection
        const errorMessage = err instanceof Error ? err.message : 'Failed to execute transaction'
        const isUserRejection = errorMessage.toLowerCase().includes('user rejected transaction')

        if (!isUserRejection) {
          setError(err instanceof Error ? err : new Error('Failed to execute transaction'))
          setState(ContractTxState.ERROR)
        } else {
          // Reset state for user rejection
          console.warn('🚫 [ContractTx] User rejected transaction')
          reset()
        }

        throw err
      }
    },
    [provider, account, addTransaction]
  )

  const reset = useCallback(() => {
    console.log('🔄 [ContractTx] Resetting state')
    setError(null)
    setState(ContractTxState.UNKNOWN)
    setHash(undefined)
  }, [])

  return {
    execute,
    state,
    error,
    hash,
    reset,
  }
}
