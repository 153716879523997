import Modal from 'components/Modal'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useSwapStore } from '../store/useSwapStore'
import { ChainLink } from './ChainLink'

const Title = styled.h2`
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.text100};
  margin: 0;
`

const Text = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.text70};
  margin: 0;
  margin-top: 2rem;
`

const Chain = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.25rem;
  row-gap: 1.25rem;
`

export const RouteModal = ({
  isOpenFlag,
  onClose,
  cost,
  hideCost,
}: {
  cost: string
  isOpenFlag: boolean
  onClose: () => void
  hideCost?: boolean
}) => {
  const { t } = useTranslation()
  const route = useSwapStore((state) => state.route)

  const pairs = useMemo(() => {
    if (!route.path || route.path.length < 2) return []

    // Create pairs from consecutive addresses in the path
    return route.path.slice(0, -1).map((address, index) => [address, route.path[index + 1]] as [string, string])
  }, [route.path])
  console.log('🚀 ~ pairs ~ route.path:', route.path)
  console.log('🚀 ~ pairs ~ pairs:', pairs)

  return (
    <Modal isOpenFlag={isOpenFlag} onDismissHandler={onClose} header={<Title>{t('swapRoute.title')}</Title>}>
      <Text>
        {hideCost ? null : `${t('swapRoute.bestPriceCost1')} ~${cost} ${t('swapRoute.bestPriceCost2')}`}
        {t('swapRoute.bestPriceCost3')}
      </Text>
      <Chain>
        {pairs.map((pair, index) => (
          <ChainLink
            key={index}
            pair={pair}
            isFirst={index === 0}
            isLast={index === pairs.length - 1}
            mode={pair[1] ? 'double' : 'single'}
          />
        ))}
      </Chain>
    </Modal>
  )
}
