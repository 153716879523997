import Modal from 'components/Modal'
import { Search } from 'components/Search'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'

import { BaseTokenList } from './BaseTokenListItem'
import { BaseToken } from './types'

const ModalTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.text100};
`

const TokenList = styled.div`
  margin: 1.5rem 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

interface TokenListModalProps<T extends BaseToken> {
  isOpen: boolean
  onDismiss: () => void
  tokens: T[]
  selectedToken?: T
  onSelect: (token: T) => void
  title: string
  searchPlaceholder?: string
  showSearch?: boolean
  filterTokens?: (tokens: T[], search: string) => T[]
  renderToken: (props: { token: T; onClick: () => void; selected?: boolean }) => ReactNode
}

export function TokenListModal<T extends BaseToken>({
  isOpen,
  onDismiss,
  tokens,
  selectedToken,
  onSelect,
  title,
  searchPlaceholder,
  showSearch = true,
  filterTokens,
  renderToken,
}: TokenListModalProps<T>) {
  const [searchValue, setSearchValue] = useState('')

  return (
    <Modal header={<ModalTitle>{title}</ModalTitle>} isOpenFlag={isOpen} onDismissHandler={onDismiss} height="auto">
      {showSearch && (
        <Search
          style={{ marginTop: '1.5rem' }}
          inputProps={{
            placeholder: searchPlaceholder,
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value),
          }}
        />
      )}
      <TokenList>
        <BaseTokenList
          tokens={tokens}
          selectedToken={selectedToken}
          onSelect={onSelect}
          searchValue={searchValue}
          filterTokens={filterTokens}
          renderToken={renderToken}
        />
      </TokenList>
    </Modal>
  )
}
